import { RELATED_NEWS } from "./actionTypes";

const loadRelatedNews = (category, except, take) => ({
    type: RELATED_NEWS.LOAD,
    category,
    except,
    take
});

const setRelatedNews = relatedNews => ({
    type: RELATED_NEWS.LOAD_SUCCESS,
    payload : relatedNews
});

const setError = error => ({
    type: RELATED_NEWS.LOAD_FAIL,
    error
});


export {loadRelatedNews, setRelatedNews, setError};
