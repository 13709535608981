import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import MainFeature from './FirstSectionMainFeature';
import SubFeature from './FirstSectionSubFeature';
import Image from '../../Image/Image';
import './FirstSectionFeature.css';
import {createStructuredSelector} from 'reselect';
import {selectAdsItems} from '../../../redux/Ads/ads.selector';
import {selectFirstSectionFeatureItems} from '../../../redux/FirstSection/FirstSection.selector';

class FirstSectionFeature extends Component {
    render() {
        const {firstSectionFeature, ads} = this.props;
        return (
            <Fragment>
                <div className="banner_section">
                    <div className="container">
                        <div className="row">
                            {firstSectionFeature.map((news, index) => (
                                index === 0 ? <MainFeature key={news.id} news={news}/> : null
                            ))}

                            <div className="col-md-3">
                                {firstSectionFeature.map((news, index) => (
                                    index > 0 ? <SubFeature key={news.id} news={news}/> : null
                                ))}
                            </div>

                            <div className="col-md-3 banner_top">
                                {ads.filter((ad, index) => index === 7).map(ad =>
                                    <Image ad={ad} key={ad.id}/>
                                )}
                                <div className="ad_banner_top">
                                    {ads.filter((ad, index) => index === 1).map(ad =>
                                        <Image ad={ad} key={ad.id}/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    firstSectionFeature : selectFirstSectionFeatureItems,
    ads : selectAdsItems
});

export default connect(
    mapStateToProps,
    null
)(FirstSectionFeature);