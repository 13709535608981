import React, {Component, Fragment} from 'react';

class LongText extends Component {
    state = {
        showAll: false
    };

    showMore = () => this.setState({showAll: true});
    showLess = () => this.setState({showAll: false});

    render() {
        const {content, limit} = this.props;
        const {showAll} = this.state;

        if (content.length <= limit) {
            return <span>{content}</span>
        }

        if (showAll) {
            return <div>
                {content}
                <span onClick={this.showLess}>Read less</span>
            </div>
        }

        const toShow = content.substring(0, limit) + "...";
        return (<Fragment>
            {toShow}<span onClick={this.showMore}></span>
        </Fragment>)
    }
}

export default LongText;
