import { NEWS } from "./actionTypes";

const INITIAL_STATE = {
    news: []
};

const newsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NEWS.LOAD_SUCCESS :
            return {
                ...state,
                news: action.payload
            };
        default :
            return state;
    }
};

export default newsReducer;
