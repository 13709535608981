import React, {Fragment, useEffect} from 'react';
import './Success.css';
import {Button} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';

const Success = ({message, icon, history}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return(
        <Fragment>
            <section className="bg_gray">
                <div className="container">
                    <div className='content'>
                        <div className={`symbol ${icon ? icon : 'fa fa-check-circle-o success'}`}/>
                        <div className='title'>{message ? message : 'অভিনন্দন !! আপনি সফলভাবে সম্পন্ন করেছেন। আপনার মেইল যাচাই করুন।'}</div>
                        <div className='text'/>
                        <Button onClick={() => history.push('/')} variant="success" size="lg">
                            <i className="fa fa-home" aria-hidden="true"/>
                            {'\u00A0'} Go To Home
                        </Button>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

export default withRouter(Success);