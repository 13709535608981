import React, {Fragment} from 'react';
import Left from './Left/Left';
import Middle from './Middle/Middle';
import Right from './Right/Right';

const FifthSection = () => {
    return (
        <Fragment>
            <section className="bg_gray">
                <div className="container">
                    <div className="row">
                        <Left/>
                        <Middle/>
                        <Right/>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};


export default FifthSection;
