import {put, call, takeEvery} from "redux-saga/effects";

import { setVerifyStatus,setError } from "./action";
import { USER_VERIFY } from "./actionTypes";
import { fetchUserVerify } from "../../api/index";

export function* handleUserVerifyLoad(action) {
    try {
        const { token } = action.token;
        const status = yield call(fetchUserVerify, token);
        yield put(setVerifyStatus(status));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchUserVerifyLoad() {
    yield takeEvery(USER_VERIFY.LOAD, handleUserVerifyLoad);
}
