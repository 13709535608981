import React, {Fragment} from 'react';
import { withRouter } from 'react-router-dom';

const PopularNewsSingleFeature = ({news, history, match}) => {
    return(
        <Fragment>
            <div className="popular_single sidebar_1" onClick={() =>
                history.push(
                    `/news/${news.categories[0].slug}/${news.id}/${news.slug}`
                )
            }>
                <img src={news.image.feature_image} alt={news.title}/>
                <div className="popular_single_content">
                    <h5>{news.title}</h5>
                    <span>{news.published_time}</span>
                    <p>
                        জাতির পিতা বঙ্গবন্ধু শেখ মুজিবুর রহমানের জন্মশতবার্ষিকী উপলক্ষে তথ্য
                    </p>
                </div>
            </div>
        </Fragment>
    )
};

export default withRouter(PopularNewsSingleFeature);