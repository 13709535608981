import { NEWS_DETAIL_SLUG } from "./actionTypes";

const loadNewsDetailSlug = (slug) => ({
    type: NEWS_DETAIL_SLUG.LOAD,
    slug
});

const setNewsDetailSlug = newsDetailSlug => ({
    type: NEWS_DETAIL_SLUG.LOAD_SUCCESS,
    payload : newsDetailSlug
});

const setError = error => ({
    type: NEWS_DETAIL_SLUG.LOAD_FAIL,
    error
});


export {loadNewsDetailSlug, setNewsDetailSlug, setError};
