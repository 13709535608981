import { INTERVIEW } from "./actionTypes";

const loadInterview = () => ({
    type: INTERVIEW.LOAD
});

const setInterview = interviews => ({
    type: INTERVIEW.LOAD_SUCCESS,
    payload : interviews
});

const setError = error => ({
    type: INTERVIEW.LOAD_FAIL,
    error
});

export {loadInterview, setInterview, setError};
