import {put, call, takeEvery} from "redux-saga/effects";

import { setNews,setError } from "./action";
import { LATEST_NEWS } from "./actionTypes";
import { fetchLatestNews } from "../../api";

export function* handleLatestNewsLoad(action) {
    try {
        const { take } = action.take;
        const news = yield call(fetchLatestNews, take);
        yield put(setNews(news));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchLatestNewsLoad() {
    yield takeEvery(LATEST_NEWS.LOAD, handleLatestNewsLoad);
}
