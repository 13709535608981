import { TAGS } from "./actionTypes";

const loadTagNews = (tag) => ({
    type: TAGS.LOAD,
    tag
});

const setTagNews = tags => ({
    type: TAGS.LOAD_SUCCESS,
    payload : tags
});

const setError = error => ({
    type: TAGS.LOAD_FAIL,
    error
});


export {loadTagNews, setTagNews, setError};
