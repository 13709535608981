import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {loadFifthLeftSectionNews} from '../../../redux/FifthSection/Left/action';
import {selectFifthLeftSectionNewsItems} from '../../../redux/FifthSection/Left/fifthLeftSection.selector';
import CardFeature from '../../commonComponent/cardView/CardFeature';
import CardList from '../../commonComponent/cardView/CardList';
import {withRouter} from 'react-router-dom';

class Left extends Component {
    componentDidMount() {
        this.props.loadFifthLeftSectionNews({serialNo: 6});
    }

    render() {
        const {fifthSectionLeft, history, match} = this.props;
        return(
            <Fragment>
                {fifthSectionLeft.length > 0 ? (
                <div className="col-md-4 telecom_list telecom_list_2">
                    <div className="interview_section popular_news_list2">
                        <div className="content_title">
                            <h3 className="category_title" onClick={() => history.push(`${match.url}category/${fifthSectionLeft[0].categories[0].slug}`)}>
                                {fifthSectionLeft[0].categories[0].title}
                            </h3>
                        </div>
                        <CardFeature news={fifthSectionLeft[0]}/>
                        <div className="popular_menu_list">
                            <ul>
                                {fifthSectionLeft.filter((news, index) => index > 0).map(news =>
                                    <CardList key={news.id} news={news}/>
                                )}

                            </ul>
                        </div>
                    </div>
                </div>
                ) : ''}
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    fifthSectionLeft: selectFifthLeftSectionNewsItems
});

const mapDispatchToProps = dispatch => ({
    loadFifthLeftSectionNews: (serialNo) => dispatch(loadFifthLeftSectionNews(serialNo))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Left));