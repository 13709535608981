import React,{Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import LongText from '../../utilities/LongText';

const SingleList = ({news, history, match}) => {
   return(
       <Fragment>
               <div className="interview_single"  onClick={() =>
                   history.push(`/news/${news.categories[0].slug}/${news.id}/${news.slug}`)
               }>
                   <img src={news.image ? news.image.list_image : ''} alt={news.title}/>
                   <div className="interview_single_content">
                       <h5>
                           {news.title}
                       </h5>
                       <span>{ news.published_time}</span>
                       <p>
                           <LongText content={news.short_description} limit='200'/>
                       </p>
                   </div>
               </div>
       </Fragment>
   )
};

export default withRouter(SingleList);