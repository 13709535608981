import { LATEST_NEWS } from "./actionTypes";

const INITIAL_STATE = {
    latestNews: []
};

const latestNewsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LATEST_NEWS.LOAD_SUCCESS :
            return {
                ...state,
                latestNews: action.payload
            };
        default :
            return state;
    }
};

export default latestNewsReducer;
