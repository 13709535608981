import {put, call, takeEvery} from "redux-saga/effects";

import {setLatestJob, setError} from "./action";
import { LATEST_JOB } from "./actionTypes";
import { fetchJobs } from "../../api/index";

export function* handleJobsLoad(action) {
    try {
        const { take } = action.take;
        const jobs = yield call(fetchJobs, take);
        yield put(setLatestJob(jobs));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}


export default function* watchJobsLoad() {
    yield takeEvery(LATEST_JOB.LOAD, handleJobsLoad);
}
