import {put, call, takeEvery} from "redux-saga/effects";

import { setYoutube,setError } from "./action";
import { YOUTUBE } from "./actionTypes";
import { fetchYoutubeVideos } from "../../api";

export function* handleYoutubeLoad(action) {
    try {
        const { take } = action.take;
        const news = yield call(fetchYoutubeVideos, take);
        yield put(setYoutube(news));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchYoutubeLoad() {
    yield takeEvery(YOUTUBE.LOAD, handleYoutubeLoad);
}
