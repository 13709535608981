import { SECOND_SECTION_RIGHT_NEWS } from "./actionTypes";

const INITIAL_STATE = {
    secondSectionRight : []
};

const secondSectionRightReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SECOND_SECTION_RIGHT_NEWS.LOAD_SUCCESS :
            return {
                ...state,
                secondSectionRight : action.payload
            };
        default :
            return state;
    }
};

export default secondSectionRightReducer;
