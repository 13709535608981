import { THIRD_SECTION_NEWS } from "./actionTypes";

const errorReducer = (state = null, action) => {
  switch (action.type) {
    case THIRD_SECTION_NEWS.LOAD_FAIL:
      return action.error;
    case THIRD_SECTION_NEWS.LOAD:
    case THIRD_SECTION_NEWS.LOAD_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default errorReducer;
