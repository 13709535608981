import {SECOND_SECTION_LEFT_NEWS} from "./actionTypes";

const loadSecondSectionLefttNews = (serialNo) => ({
    type: SECOND_SECTION_LEFT_NEWS.LOAD,
    serialNo
});

const setSecondSectionLefttNews = news => ({
    type: SECOND_SECTION_LEFT_NEWS.LOAD_SUCCESS,
    payload : news
});

const setError = error => ({
    type: SECOND_SECTION_LEFT_NEWS.LOAD_FAIL,
    error
});


export {loadSecondSectionLefttNews, setSecondSectionLefttNews, setError};
