import React, {Fragment} from 'react';
import { withRouter } from 'react-router-dom';

const PopularNewsSidebarList = ({popularNews, history, match}) => {
    return (
        <Fragment>
            <div className="popular_single sidebar_2">
                <div className="popular_menu_list">
                    <ul>
                        {popularNews.map(news => (
                            <li key={news.id} onClick={() =>
                                history.push(
                                    `/news/${news.categories[0].slug}/${news.id}/${news.slug}`
                                )
                            }>
                                {news.title}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Fragment>
    )
};

export default withRouter(PopularNewsSidebarList);