import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import './Left.css';
import {createStructuredSelector} from 'reselect';
import {selectSecondSectionLeftItems} from '../../../redux/secondSection/left/secondSectionLeft.selector';
import {loadSecondSectionLefttNews} from '../../../redux/secondSection/left/action';
import CommonMainFeature from '../../commonComponent/CommonMainFeature';
import CommonListNews from '../../commonComponent/CommonListNews';
import {withRouter} from 'react-router-dom';

class Left extends Component {
    componentDidMount() {
        this.props.loadSecondSectionLefttNews({serialNo: 2});
    }

    render() {
        const {secondSectionLeft, history, match} = this.props;
        return (
            <Fragment>
                {secondSectionLeft.length > 0 ? (
                <div className="col-md-8">
                    <div className="entertainment_section">
                        <div className="content_title">
                            <h3 className="category_title" onClick={() => history.push(`${match.url}category/${secondSectionLeft[0].categories[0].slug}`)}>
                                {secondSectionLeft[0].categories[0].title}
                            </h3>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <CommonMainFeature news={secondSectionLeft[0]}/>
                            </div>
                            <div className="col-md-6">
                                {secondSectionLeft.filter((news,index) => index > 0).map((news, index) => (
                                        <CommonListNews key={news.id} textLimit="50" news={news}/>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                ) : ''}
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    secondSectionLeft : selectSecondSectionLeftItems,
});

const mapDispatchToProps = dispatch => ({
    loadSecondSectionLefttNews: (serialNo) => dispatch(loadSecondSectionLefttNews(serialNo))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Left));