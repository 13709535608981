import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import Interview from '../Interview/Interview';
import {createStructuredSelector} from 'reselect';
import {selectSecondSectionRightItems} from '../../redux/secondSection/right/secondSectionRight.selector';
import {loadSecondSectionRighttNews} from '../../redux/secondSection/right/action';

class SideInterview extends  Component {
    componentDidMount() {
        this.props.loadSecondSectionRighttNews({serialNo: 3});
    }
    render() {
        const {interviews} = this.props;
        return(
            <Fragment>
                <div className="interview_section  sidebar_3">
                    <div className="content_title">
                        <h3>
                            সাক্ষাৎকার
                        </h3>
                    </div>
                    {interviews.map(interview => (
                        <Interview key={interview.id} interview={interview} isShowDetais={false} />
                    ))}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    interviews : selectSecondSectionRightItems,
});

const mapDispatchToProps = dispatch => ({
    loadSecondSectionRighttNews: (serialNo) => dispatch(loadSecondSectionRighttNews(serialNo))
});

export default connect(mapStateToProps, mapDispatchToProps)(SideInterview);