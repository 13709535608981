import {put, call, takeEvery} from "redux-saga/effects";

import {setFirstSectionNews, setError} from "./action";
import {FIRST_SECTION_NEWS} from "./actionTypes";
import {fetchNews} from "../../api/index";

export function* handleFirstSectionLoad(action) {
    try {
        const { serialNo } = action.serialNo;
        const news = yield call(fetchNews, serialNo, 6, null);
        yield put(setFirstSectionNews(news));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchFirstSectionLoad() {
    yield takeEvery(FIRST_SECTION_NEWS.LOAD, handleFirstSectionLoad);
}
