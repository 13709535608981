import {CATEGORY_NEWS} from "./actionTypes";

const INITIAL_STATE = {
    categoryNews : []
};

const latestCategoryNewsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case CATEGORY_NEWS.LOAD_SUCCESS :
            return {
                ...state,
                categoryNews : action.payload
            };
        default :
            return state;
    }
};

export default latestCategoryNewsReducer;
