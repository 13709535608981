import {USER_VERIFY} from "./actionTypes";

const INITIAL_STATE = {
    userVerifyStatus : []
};

const userVerifyReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case USER_VERIFY.LOAD_SUCCESS :
            return {
                ...state,
                userVerifyStatus : action.payload
            };
        default :
            return state;
    }
};

export default userVerifyReducer;
