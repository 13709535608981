import { LATEST_JOB } from "./actionTypes";

const loadLatestJob = (take) => ({
    type: LATEST_JOB.LOAD,
    take
});

const setLatestJob = jobs => ({
    type: LATEST_JOB.LOAD_SUCCESS,
    payload : jobs
});

const setError = error => ({
    type: LATEST_JOB.LOAD_FAIL,
    error
});

export {loadLatestJob, setLatestJob, setError};
