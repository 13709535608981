import React, { Fragment } from 'react';
import {withRouter} from 'react-router-dom';
import './CategoryFeature.css'

const CategoryFeature = ({ news, history, match }) => {
    return (
        <Fragment>
            <div className="category-feature-wrapper" onClick={() =>
                history.push(`/news/${news.categories[0].slug}/${news.id}/${news.slug}`)
            }>
                <div className="inner_banner">
                    <img className="feature_image" src={ news.image && news.image.details_image } alt={news.title}/>
                </div>
                <h3>{ news.title }</h3>
            </div>
        </Fragment>
    )
};

export default withRouter(CategoryFeature);