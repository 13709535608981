import { SETTING } from "./actionTypes";

const settingErrorReducer = (state = null, action) => {
  switch (action.type) {
    case SETTING.LOAD_FAIL:
      return action.error;
    case SETTING.LOAD:
    case SETTING.LOAD_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default settingErrorReducer;
