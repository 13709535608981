import React, {Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import LongText from '../../utilities/LongText';
import './CommonMainFeature.css';

const CommonMainFeature = ({news,history, match}) => {
    return (
        <Fragment>
            <div className="entertainment_main_banner" onClick={() =>
                history.push(
                    `${match.url}news/${news.categories[0].slug}/${news.id}/${news.slug}`
                )
            }>
                <img src={news.image ? news.image.highlighted_image : ''} alt={news.title}/>
                <div className="entertainment_main_banner_content">
                    <h5>{news.title}</h5>
                    <span>{news.published_time}</span>
                    <p>
                        <LongText content={news.short_description} limit='120'/>
                    </p>
                </div>
            </div>
        </Fragment>
    )
};

export default withRouter(CommonMainFeature);