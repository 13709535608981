import React,{Fragment} from 'react';
import Gallery from './Gallery/Gallery';
import Youtube from './Youtube/Youtube';

const Media = () => {
    return(
        <Fragment>
            <section className="bg_white">
                <div className="container">
                    <div className="row">
                        <Gallery/>
                        <Youtube/>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

export default Media;