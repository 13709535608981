import { FIFTH_MIDDLE_SECTION_NEWS } from "./actionTypes";

const INITIAL_STATE = {
    fifthMiddleSectionNews : []
};

const fifthMiddleSectionNewsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case FIFTH_MIDDLE_SECTION_NEWS.LOAD_SUCCESS :
            return {
                ...state,
                fifthMiddleSectionNews : action.payload
            };
        default :
            return state;
    }
};

export default fifthMiddleSectionNewsReducer;
