import {ARCHIVE} from "./actionTypes";

const INITIAL_STATE = {
    archives: []
};

const archivesReducer = (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case ARCHIVE.LOAD_SUCCESS :
            return {
                ...state,
                archives: action.payload
            };
        default :
            return state;
    }
};

export default archivesReducer;
