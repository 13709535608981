import { USER } from './actionTypes';

const loadUser = () => ({
    type: USER.LOAD
});

const setCurrentUser = (user) => ({
    type : USER.LOAD_SUCCESS,
    payload : user
});

const setError = error => ({
    type: USER.LOAD_FAIL,
    error
});

const logOutUser = () => ({
    type : USER.LOG_OUT
});

export { loadUser, setCurrentUser, logOutUser, setError };
