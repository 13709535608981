import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import PopularFeature from '../PolpularNewsSidebarFeature/PopularNewsSingleFeature';
import PopularList from '../PopularNewsSidebarList/PopularNewsSidebarList';
import {loadNews} from '../../redux/popularNews/action';
import {createStructuredSelector} from 'reselect';
import {
    selectPopularFeature,
    selectPopularList
} from '../../redux/popularNews/popularNews.selectior';

class PopularNewsSidebar extends Component {
    componentDidMount() {
        this.props.loadNews({take: 5});
    }

    render() {
        const {popularFeatureNews, popularListNews} = this.props;
        return (
            <Fragment>
                { popularFeatureNews[0] ? (
                    <Fragment>
                        <PopularFeature news={popularFeatureNews[0]}/>
                        <PopularList popularNews={popularListNews}/>
                    </Fragment>

                )
                    : '' }
            </Fragment>
        )
    }
}


const mapStateToProps = createStructuredSelector({
    popularFeatureNews: selectPopularFeature,
    popularListNews: selectPopularList
});

const mapDispatchToProps = dispatch => ({
    loadNews: (take) => dispatch(loadNews(take))
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PopularNewsSidebar);