import React, {Fragment} from 'react';
import Archive from '../Archive/Archive';
import LatestJob from '../LatestJob/LatestJob';

const ArchiveLatestJob = () => {
    return(
        <Fragment>
            <div className="arhive_job_section">
                <div className="row">
                    <Archive/>
                    <LatestJob />
                </div>
            </div>
        </Fragment>
    )
};

export default ArchiveLatestJob;