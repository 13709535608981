import {THIRD_SECTION_NEWS} from "./actionTypes";

const loadThirdSectiontNews = (serialNo) => ({
    type: THIRD_SECTION_NEWS.LOAD,
    serialNo
});

const setThirdSectiontNews = news => ({
    type: THIRD_SECTION_NEWS.LOAD_SUCCESS,
    payload : news
});

const setError = error => ({
    type: THIRD_SECTION_NEWS.LOAD_FAIL,
    error
});


export {loadThirdSectiontNews, setThirdSectiontNews, setError};
