import {put, call, takeEvery} from "redux-saga/effects";

import { setRelatedNews,setError } from "./action";
import { RELATED_NEWS } from "./actionTypes";
import { fetchRelatedNews } from "../../api";

export function* handleRelatedNewsLoad(action) {
    try {
        const { category, except, take } = action.category;
        const news = yield call(fetchRelatedNews, category, except, take);
        yield put(setRelatedNews(news));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchRelatedNewsLoad() {
    yield takeEvery(RELATED_NEWS.LOAD, handleRelatedNewsLoad);
}
