import {put, call, takeEvery} from "redux-saga/effects";

import {setSecondSectionRighttNews, setError} from "./action";
import {SECOND_SECTION_RIGHT_NEWS} from "./actionTypes";
import {fetchNews} from "../../../api/index";

export function* handleSecondSectionRightLoad(action) {
    try {
        const { serialNo } = action.serialNo;
        const news = yield call(fetchNews, serialNo, 3, null);
        yield put(setSecondSectionRighttNews(news));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchSecondSectionRightLoad() {
    yield takeEvery(SECOND_SECTION_RIGHT_NEWS.LOAD, handleSecondSectionRightLoad);
}
