import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {loadArchive} from '../../redux/archive/action';
import {selectArchiveItems} from '../../redux/archive/archive.selector';
import SingleList from '../sixthSection/List';

class Archive extends Component {
    componentDidMount() {
        this.props.loadArchive({take: 3});
    }
    render() {
        const {archives} = this.props;
        return(
            <Fragment>
                <div className="col-sm-6">
                    <div className="content_title">
                        <h3>
                            আর্কাইভ থেকে
                        </h3>
                    </div>
                    <div className="new_technology">
                        {archives ? archives.map(news => <SingleList key={news.id} news={news} textLimit="30"/>) : ''}
                    </div>
                </div>
            </Fragment>
        )
    }
};

const mapStateToProps = createStructuredSelector({
    archives : selectArchiveItems
});

const mapDispatchToProps = dispatch => ({
    loadArchive: (take) => dispatch(loadArchive(take))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Archive);