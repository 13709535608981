import {put, call, takeEvery} from "redux-saga/effects";

import { setJobDetail,setError } from "./action";
import { JOB_DETAIL } from "./actionTypes";
import { fetchJobDetail } from "../../api/index";

export function* handleJobDetailLoad(action) {
    try {
        const { id } = action.id;
        const detail = yield call(fetchJobDetail, id);
        yield put(setJobDetail(detail));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchJobDetailLoad() {
    yield takeEvery(JOB_DETAIL.LOAD, handleJobDetailLoad);
}
