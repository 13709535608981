import {FIRST_SECTION_NEWS} from "./actionTypes";

const loadFirstSectionNews = (serialNo) => ({
    type: FIRST_SECTION_NEWS.LOAD,
    serialNo
});

const setFirstSectionNews = firstSectionNews => ({
    type: FIRST_SECTION_NEWS.LOAD_SUCCESS,
    payload : firstSectionNews
});

const setError = error => ({
    type: FIRST_SECTION_NEWS.LOAD_FAIL,
    error
});


export {loadFirstSectionNews, setFirstSectionNews, setError};
