import { LATEST_FEATURE_NEWS } from "./actionTypes";

const latestFeatureError = (state = null, action) => {
  switch (action.type) {
    case LATEST_FEATURE_NEWS.LOAD_FAIL:
      return action.error;
    case LATEST_FEATURE_NEWS.LOAD:
    case LATEST_FEATURE_NEWS.LOAD_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default latestFeatureError;
