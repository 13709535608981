import {put, call, takeEvery} from "redux-saga/effects";

import {setThirdSectiontNews, setError} from "./action";
import {THIRD_SECTION_NEWS} from "./actionTypes";
import {fetchNews} from "../../api/index";

export function* handleThirdSectionNewsLoad(action) {
    try {
        const { serialNo } = action.serialNo;
        const news = yield call(fetchNews, serialNo, 6, null);
        yield put(setThirdSectiontNews(news));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchThirdSectionNewsLoad() {
    yield takeEvery(THIRD_SECTION_NEWS.LOAD, handleThirdSectionNewsLoad);
}
