import React, {useState} from 'react';
import {Toast, Row, Col} from 'react-bootstrap';
import './Toast.css';

const ToastMessage = ({isShow, message, type}) => {
    const [show, setShow] = useState(isShow);
    return (
        <Row>
            <Col xs={6}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide style={{
                    position: 'fixed',
                    top: '10px',
                    right: '7px',
                    width: '100vh',
                    zIndex: '1000000'
                }} className={type===2 ? "toast-danger" : "toast-success"}>
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded mr-2"
                            alt=""
                        />
                        <strong className="mr-auto">{type === 2 ? 'Error' : 'Success'} </strong>
                    </Toast.Header>
                    <Toast.Body>{message}</Toast.Body>
                </Toast>
            </Col>
        </Row>
    );
};

export default ToastMessage;