import {put, call, takeEvery} from "redux-saga/effects";

import { setAds, setError } from "./action";
import { ADS } from "./actionTypes";
import { fetchAds } from "../../api";

export function* handleAdsLoad(action) {
    try {
        const ads = yield call(fetchAds);
        yield put(setAds(ads));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchAdsLoad() {
    yield takeEvery(ADS.LOAD, handleAdsLoad);
}
