import { SECOND_SECTION_LEFT_NEWS } from "./actionTypes";

const INITIAL_STATE = {
    secondSectionLeft : []
};

const secondSectionLeftReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SECOND_SECTION_LEFT_NEWS.LOAD_SUCCESS :
            return {
                ...state,
                secondSectionLeft : action.payload
            };
        default :
            return state;
    }
};

export default secondSectionLeftReducer;
