import {SECOND_SECTION_RIGHT_NEWS} from "./actionTypes";

const loadSecondSectionRighttNews = (serialNo) => ({
    type: SECOND_SECTION_RIGHT_NEWS.LOAD,
    serialNo
});

const setSecondSectionRighttNews = news => ({
    type: SECOND_SECTION_RIGHT_NEWS.LOAD_SUCCESS,
    payload : news
});

const setError = error => ({
    type: SECOND_SECTION_RIGHT_NEWS.LOAD_FAIL,
    error
});


export {loadSecondSectionRighttNews, setSecondSectionRighttNews, setError};
