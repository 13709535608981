import {put, call, takeEvery} from "redux-saga/effects";

import { setNews,setError } from "./action";
import { POPULAR_NEWS } from "./actionTypes";
import { fetchPopularNews } from "../../api";

export function* handlePopularNewsLoad(action) {
    try {
        const { take } = action.take;
        const news = yield call(fetchPopularNews, take);
        yield put(setNews(news));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchPopularNewsLoad() {
    yield takeEvery(POPULAR_NEWS.LOAD, handlePopularNewsLoad);
}
