import React, {Component, Fragment} from 'react';
import './Search.css';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {loadSearch} from '../../redux/search/action';
import {selectSearchItems} from '../../redux/search/search.selectior';
import {Media} from 'react-bootstrap';
import LongText from '../../utilities/LongText';

class Search extends Component {
    componentDidMount() {
        const {match} = this.props;
        const {value} = match.params;
        this.props.loadSearch({search: value});
    }

    render() {
        const {news, history} = this.props;
        return (
            <Fragment>
                <div className="container">
                    <div className="search-content">
                        <ul className="list-unstyled">
                            {news ? news.map(item => (
                                <Media key={item.id} className="list-item" as="li" onClick={() =>
                                    history.push(`/news/${item.categories[0].slug}/${item.id}/${item.slug}`)
                                }>
                                    <img
                                        width={64}
                                        height={64}
                                        className="mr-3"
                                        src={item.image ? item.image.list_image : ''}
                                        alt={item.title}
                                    />
                                    <Media.Body>
                                        <h5>{item.title}</h5>
                                        <p>
                                            <LongText content={item.short_description} limit='250'/>
                                        </p>
                                    </Media.Body>
                                </Media>
                            )) : ''}

                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    news: selectSearchItems
});

const mapDispatchToProps = dispatch => ({
    loadSearch: (search) => dispatch(loadSearch(search)),
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Search);
