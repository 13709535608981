import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import Sidebar from '../../components/sidebar/Sidebar';
import {createStructuredSelector} from 'reselect';
import {loadGallery} from '../../redux/Gallery/action';
import {selectGalleriesItems} from '../../redux/Gallery/gallery.selectior';
import PhotoWithCaption from '../../components/commonComponent/PhotoWithCaption/PhotoWithCaption';
import {SRLWrapper} from "simple-react-lightbox";
import './PhotoGallery.css';

class PhotoGallery extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.loadGallery({take: 36});
    }


    render() {
        const {galleries} = this.props;
        return (
            <Fragment>
                <section className="bg_white">
                    <div className="banner_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="inner_content">
                                        <h3>ফটো গ্যালারি</h3>
                                        {/*<p>আপডেট ০২ মার্চ ২০২০ ১৫:০২</p>*/}
                                        <SRLWrapper>
                                            <div className="row">
                                                {galleries ? galleries.map(photo => (
                                                    <div className="col-sm-4" key={photo.id}>
                                                        <PhotoWithCaption item={photo}/>
                                                    </div>
                                                )) : ''}
                                            </div>
                                        </SRLWrapper>
                                    </div>
                                </div>
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    galleries: selectGalleriesItems,
});

const mapDispatchToProps = dispatch => ({
    loadGallery: (take) => dispatch(loadGallery(take))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PhotoGallery);