import {RELATED_NEWS} from "./actionTypes";

const INITIAL_STATE = {
    relatedNews : []
};

const relatedNewsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case RELATED_NEWS.LOAD_SUCCESS :
            return {
                ...state,
                relatedNews : action.payload
            };
        default :
            return state;
    }
};

export default relatedNewsReducer;
