import React, {Fragment} from 'react';
import './PhotoWithCaption.css';
import LongText from '../../../utilities/LongText';

const PhotoWithCaption = ({item}) => {
    return(
        <Fragment>
            <div className="gallery_single">
                <img src={item.list_image} alt={item.caption}/>
                <div className="gallery_des">
                    <p><LongText content={item.caption} limit='60'/></p>
                </div>
            </div>
        </Fragment>
    )
};

export default PhotoWithCaption;