import {put, call, takeEvery} from "redux-saga/effects";

import {setFifthMiddleSectionNews, setError} from "./action";
import {FIFTH_MIDDLE_SECTION_NEWS} from "./actionTypes";
import {fetchNews} from "../../../api/index";

export function* handleFifthMiddleSectionNewsLoad(action) {
    try {
        const { serialNo } = action.serialNo;
        const news = yield call(fetchNews, serialNo, 4, null);
        yield put(setFifthMiddleSectionNews(news));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}


export default function* watchFifthMiddleSectionNewsLoad() {
    yield takeEvery(FIFTH_MIDDLE_SECTION_NEWS.LOAD, handleFifthMiddleSectionNewsLoad);
}
