import { GALLERY } from "./actionTypes";

const galleryErrorReducer = (state = null, action) => {
  switch (action.type) {
    case GALLERY.LOAD_FAIL:
      return action.error;
    case GALLERY.LOAD:
    case GALLERY.LOAD_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default galleryErrorReducer;
