import {FIFTH_MIDDLE_SECTION_NEWS} from "./actionTypes";

const loadFifthMiddleSectionNews = (serialNo) => ({
    type: FIFTH_MIDDLE_SECTION_NEWS.LOAD,
    serialNo
});

const setFifthMiddleSectionNews = news => ({
    type: FIFTH_MIDDLE_SECTION_NEWS.LOAD_SUCCESS,
    payload : news
});

const setError = error => ({
    type: FIFTH_MIDDLE_SECTION_NEWS.LOAD_FAIL,
    error
});


export {loadFifthMiddleSectionNews, setFifthMiddleSectionNews, setError};
