import {SIXTH_SECTION_LEFT_NEWS, SIXTH_SECTION_RIGHT_NEWS} from "./actionTypes";

const INITIAL_STATE = {
    sixthSectionLeftNews: [],
    sixthSectionRightNews: []
};

const sixthSectionNewsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIXTH_SECTION_LEFT_NEWS.LOAD_SUCCESS :
            return {
                ...state,
                sixthSectionLeftNews: action.payload
            };
        case SIXTH_SECTION_RIGHT_NEWS.LOAD_SUCCESS :
            return {
                ...state,
                sixthSectionRightNews: action.payload
            };
        default :
            return state;
    }
};

export default sixthSectionNewsReducer;
