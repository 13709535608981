import { SUBSCRIPTION_VERIFY } from "./actionTypes";

const subscriptionVerifyErrorReducer = (state = null, action) => {
  switch (action.type) {
    case SUBSCRIPTION_VERIFY.LOAD_FAIL:
      return action.error;
    case SUBSCRIPTION_VERIFY.LOAD:
    case SUBSCRIPTION_VERIFY.LOAD_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default subscriptionVerifyErrorReducer;
