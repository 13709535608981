import React, {Fragment, Component} from 'react';
import CardFeature from '../CardFeature';
import SingleList from '../List';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {loadSixthSectiontRightNews} from '../../../redux/sixthSection/action';
import {selectSixthSectionNewsItems} from '../../../redux/sixthSection/sixthSection.selector';
import {withRouter} from 'react-router-dom';

class RightSection extends Component {
    componentDidMount() {
        this.props.loadSixthSectiontRightNews({serialNo: 10});
    }

    render() {
        const {sixthSectionRightNews} = this.props.sixthSectionRight;
        const {history, match} = this.props;
        return (
            <Fragment>
                {sixthSectionRightNews.length > 0 ? (
                    <div className="col-sm-6">
                        <div className="content_title">
                            <h3 className="category_title" onClick={() => history.push(`${match.url}category/${sixthSectionRightNews[0].categories[0].slug}`)}>
                                {sixthSectionRightNews[0].categories[0].title}
                            </h3>
                        </div>
                        <div className="new_technology">
                            <Fragment>
                                <CardFeature news={sixthSectionRightNews[0]}/>
                                {sixthSectionRightNews.filter((news, index) => index > 0).map((news) => <SingleList
                                    key={news.id} news={news} textLimit="30"/>)}
                            </Fragment>

                        </div>
                    </div>
                ) : ''}
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    sixthSectionRight: selectSixthSectionNewsItems
});

const mapDispatchToProps = dispatch => ({
    loadSixthSectiontRightNews: (serialNo) => dispatch(loadSixthSectiontRightNews(serialNo))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RightSection));