import React, {Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import './FirstSectionSubFeature.css';
import LongText from '../../../utilities/LongText';

const MainFeature = ({news, history, match}) => {
    return (
        <Fragment>
            <div className="main_banner_two" onClick={() =>
                history.push(
                    `${match.url}news/${news.categories[0].slug}/${news.id}/${news.slug}`
                )
            }>
                <img src={news.image ? news.image.highlighted_image : ''} alt={news.title}/>
                <h3><LongText content={news.title} limit='40'/></h3>
            </div>
        </Fragment>
    )
};

export default withRouter(MainFeature);
