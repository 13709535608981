import React from 'react';
import { withRouter } from 'react-router-dom';
import './CardFeature.css';

const CardFeature = ({news,history, match}) => {
    return (
        <div className="popular_single" onClick={() =>
            history.push(
                `${match.url}news/${news.categories[0].slug}/${news.id}/${news.slug}`
            )
        }>
            <img src={news.image.highlighted_image} alt={news.title}/>
            <div className="popular_single_content">
                <h4>{news.title}</h4>
            </div>
        </div>
    )
};

export default withRouter(CardFeature);