import React, { Component } from "react";
import HeaderTop from "./HeaderTop";
import NavContent from "./NavContent";

class TopBar extends Component {
  render() {
    return (
      <section>
        <div className='container'>
          <HeaderTop />
          <NavContent />
        </div>
      </section>
    );
  }
}

export default TopBar;
