import {put, call, takeEvery} from "redux-saga/effects";

import {setNews, setError} from "./action";
import {NEWS} from "./actionTypes";
import { fetchCategoryWiseNews } from "../../api/index";

export function* handleNewsLoad() {
    try {
        const news = yield call(fetchCategoryWiseNews);
        yield put(setNews(news));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}


export default function* watchNewsLoad() {
    yield takeEvery(NEWS.LOAD, handleNewsLoad);
}
