import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import LongText from '../../utilities/LongText';
import './CardFeature.css';

const CardFeature = ({news, history, match}) => {
    return (
        <Fragment>
            <div className="new_technology_single new_technology_single_main" onClick={() =>
                history.push(
                    `${match.url}news/${news.categories[0].slug}/${news.id}/${news.slug}`
                )
            }>
                <div className="new_technology_single_img">
                    <img src={news.image.list_image} alt={news.title}/>
                </div>
                <div className="new_technology_single_content">
                    <h3>{news.title}</h3>
                    <p>
                        <LongText content={news.short_description} limit='100'/>
                    </p>
                    <div className="new_technology_single_content_bottom">
                        <div className="date">
                            <p>{news.published_time}</p>
                        </div>
                        <div className="comments">
                            <p>{news.comments_count} মন্তব্য</p>
                        </div>
                    </div>
                </div>
                <div className="clearfix"/>
            </div>
        </Fragment>
    )
};

export default withRouter(CardFeature);