import {LATEST_FEATURE_NEWS} from "./actionTypes";

const INITIAL_STATE = {
    latestFeatureNews: []
};

const latestFeatureNewsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LATEST_FEATURE_NEWS.LOAD_SUCCESS :
            return {
                ...state,
                latestFeatureNews: action.payload
            };
        default :
            return state;
    }
};

export default latestFeatureNewsReducer;
