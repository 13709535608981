import {put, call, takeEvery} from "redux-saga/effects";

import {setLatestFeatureNews, setLatestFetureError} from "./action";
import {LATEST_FEATURE_NEWS} from "./actionTypes";
import {fetchNews} from "../../api/index";

export function* handleLatestFeatureNewsLoad() {
    try {
        const latestFeatureNews = yield call(fetchNews, 'সর্বশেষ', 3, null);
        yield put(setLatestFeatureNews(latestFeatureNews));
    } catch (error) {
        yield put(setLatestFetureError(error.toString()));
    }
}


export default function* watchLatestFeatureNewsLoad() {
    yield takeEvery(LATEST_FEATURE_NEWS.LOAD, handleLatestFeatureNewsLoad);
}
