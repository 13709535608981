import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import './Right.css';
import {createStructuredSelector} from 'reselect';
import {selectSecondSectionRightItems} from '../../../redux/secondSection/right/secondSectionRight.selector';
import {loadSecondSectionRighttNews} from '../../../redux/secondSection/right/action';
import CardFeature from '../../commonComponent/cardView/CardFeature';
import CardList from '../../commonComponent/cardView/CardList';
import {withRouter} from 'react-router-dom';

class Right extends Component {
    componentDidMount() {
        this.props.loadSecondSectionRighttNews({serialNo: 3});
    }

    render() {
        const {secondSectionRight, history, match} = this.props;
        return (
            <Fragment>
                {secondSectionRight.length > 0 ? (
                    <div className="col-md-4">
                        <div className="interview_section popular_news_list">
                            <div className="content_title">
                                <h3 className="category_title" onClick={() => history.push(`${match.url}category/${secondSectionRight[0].categories[0].slug}`)}>
                                    {secondSectionRight[0].categories[0].title}
                                </h3>
                            </div>
                            <CardFeature news={secondSectionRight[0]}/>
                            <div className="popular_menu_list">
                                <ul>
                                    {secondSectionRight.filter((news, index) => index > 0).map(news =>
                                        <CardList key={news.id} news={news}/>
                                    )}

                                </ul>
                            </div>
                        </div>
                    </div>
                ) : ''}
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    secondSectionRight: selectSecondSectionRightItems,
});

const mapDispatchToProps = dispatch => ({
    loadSecondSectionRighttNews: (serialNo) => dispatch(loadSecondSectionRighttNews(serialNo))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Right));