import React, { Component, Fragment } from "react";
import { loadNews } from "../../redux/latestNews/action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {selectLatestNewsItems} from '../../redux/latestNews/latestNews.selectior';
import {createStructuredSelector} from 'reselect';

class NewsTicker extends Component {
    componentDidMount() {
        this.props.loadNews({take : 10});
    }

    render() {
        const { latestNews, history, match } = this.props;
        return (
            <Fragment>
                <section className='news_ticker'>
                    <div className='container'>
                        <div className='wrap'>
                            <div id='ticker'>
                                <div className='title'>লাইভ আপডেট</div>
                                <ul>
                                   {latestNews.map(news => (
                                        <li
                                            style={{ cursor: "pointer" }}
                                            key={news.id}
                                            onClick={() =>
                                                history.push(
                                                    `${match.url}news/${news.categories[0].slug}/${news.id}/${news.slug}`
                                                )
                                            }
                                        >
                                            {news.title}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    latestNews : selectLatestNewsItems
});

const mapDispatchToProps = dispatch => ({
    loadNews: (take) => dispatch(loadNews(take))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(NewsTicker));
