import React, {Fragment} from 'react';
import FirstSection from '../../components/FirstSection/FristSection';
import PopularNews from '../../components/popularNews/PopularNews';
import FifthSection from '../../components/FifthSection/FifthSection';
import SecondSection from '../../components/SecondSection/SecondSection';
import ThirdSection from '../../components/ThirdSection/ThirdSection';
import FourthSection from '../../components/FourthSection/FourthSection';
import TechnologyArchive from '../../components/technology-archive/TechnoloyArchive';
import Media from '../../components/Media/Media';

const Home = () => {
    return(
        <Fragment>
            <FirstSection/>
            <SecondSection />
            <ThirdSection/>
            <FourthSection/>
            <FifthSection/>
            <TechnologyArchive/>
            <PopularNews/>
            <Media/>
        </Fragment>
    );
};

export default Home;