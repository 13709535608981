import React,{Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import LongText from '../../../utilities/LongText';

const SingleList = ({news, history, match}) => {
    return (
        <Fragment>
            <div className="col-md-4">
                <div className="banner_bottom_single clearfix" onClick={() =>
                    history.push(
                        `${match.url}news/${news.categories[0].slug}/${news.id}/${news.slug}`
                    )
                }>
                    <img src={news.image.list_image} alt={news.title}/>
                    <p>
                        <LongText content={news.title} limit='60'/>
                    </p>
                </div>
            </div>
        </Fragment>
    )
};

export default withRouter(SingleList);