import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {registerTokenCheck} from '../../redux/userVerification/action';
import {withRouter} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';
import {selectUserVerifyStatusItem} from '../../redux/userVerification/userVerify.selector';
import Message from '../Success/Success';
import {setCurrentUser} from '../../redux/user/action';

class UserVerification extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        const {match} = this.props;
        const {token} = match.params;
        this.props.registerTokenCheck({token: token});
    }

    componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);
        const {dispatch,status} = this.props;
        if (status !=='errors') {
            dispatch(setCurrentUser(status))
        }
    }

    render() {
        const {status} = this.props;
        //console.log(status);
        return (
            <Fragment>
                {status !== 'errors' ?
                    <Message message="অভিনন্দন !! আপনি সফলভাবে সম্পন্ন করেছেন।"/>
                    : <Message message="দুঃখিত !! টোকেন সঠিক না।" icon="fa fa-times error"/>}
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    status: selectUserVerifyStatusItem
});

const mapDispatchToProps = dispatch => ({
    registerTokenCheck: (token) => dispatch(registerTokenCheck(token)),
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UserVerification));