import {put, call, takeEvery} from "redux-saga/effects";

import {setSecondSectionLefttNews, setError} from "./action";
import {SECOND_SECTION_LEFT_NEWS} from "./actionTypes";
import {fetchNews} from "../../../api/index";

export function* handleSecondSectionLeftLoad(action) {
    try {
        const { serialNo } = action.serialNo;
        const news = yield call(fetchNews, serialNo, 4, null);
        yield put(setSecondSectionLefttNews(news));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchSecondSectionLeftLoad() {
    yield takeEvery(SECOND_SECTION_LEFT_NEWS.LOAD, handleSecondSectionLeftLoad);
}
