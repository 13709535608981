import {FOURTH_SECTION_NEWS} from "./actionTypes";

const loadFourthSectionNews = (serialNo) => ({
    type: FOURTH_SECTION_NEWS.LOAD,
    serialNo
});

const setFourthSectionNews = news => ({
    type: FOURTH_SECTION_NEWS.LOAD_SUCCESS,
    payload : news
});

const setError = error => ({
    type: FOURTH_SECTION_NEWS.LOAD_FAIL,
    error
});


export {loadFourthSectionNews, setFourthSectionNews, setError};
