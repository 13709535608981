import {put, call, takeEvery} from "redux-saga/effects";

import { setNewsDetailSlug,setError } from "./action";
import { NEWS_DETAIL_SLUG } from "./actionTypes";
import { fetchNewsDetailSlug } from "../../api/index";

export function* handleNewsDetailSlugLoad(action) {
    try {
        const { slug } = action.slug;
        const newsDetail = yield call(fetchNewsDetailSlug, slug);
        yield put(setNewsDetailSlug(newsDetail));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchNewsDetailSlugLoad() {
    yield takeEvery(NEWS_DETAIL_SLUG.LOAD, handleNewsDetailSlugLoad);
}
