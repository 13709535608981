import React, { Component} from 'react';
import { FacebookProvider, Page } from 'react-facebook';

class FbPage extends Component {
    render() {
        const {height} = this.props;
        return (
            <FacebookProvider appId="344372429094642">
                <Page href="https://www.facebook.com/obiroto1/" height={height ? height : "380"} tabs="timeline" />
            </FacebookProvider>
        );
    }
}

export default FbPage;