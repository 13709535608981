import {put, call, takeEvery} from "redux-saga/effects";

import { setCategoryNews,setError } from "./action";
import { CATEGORY_NEWS } from "./actionTypes";
import { fetchNewsDependOnCategory } from "../../api";

export function* handleCategoryNewsLoad(action) {
    try {
        const { category, pageNumber } = action.category;
        const categoryNews = yield call(fetchNewsDependOnCategory, category, pageNumber);
        yield put(setCategoryNews(categoryNews));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}


export default function* watchLatestNewsLoad() {
    yield takeEvery(CATEGORY_NEWS.LOAD, handleCategoryNewsLoad);
}
