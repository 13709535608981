import {NEWS_DETAIL} from "./actionTypes";

const latestNewsDetailReducer = (state = [], action) => {
    if (action.type === NEWS_DETAIL.LOAD_SUCCESS) {
        return [
            action.newsDetail
        ];
    }
    return state;
};

export default latestNewsDetailReducer;
