import { LATEST_LIST_NEWS } from "./actionTypes";

const loadLatestListNews = () => ({
    type: LATEST_LIST_NEWS.LOAD
});

const setLatestListNews = latestListNews => ({
    type: LATEST_LIST_NEWS.LOAD_SUCCESS,
    latestListNews
});

const setLatestListError = error => ({
    type: LATEST_LIST_NEWS.LOAD_FAIL,
    error
});


export {loadLatestListNews, setLatestListNews, setLatestListError};
