import React, {Fragment, Component} from 'react';
import FirstSectionFeature from './FirstSectionFeature/FirstSectionFeature';
import FirstSectionList from './FirstSectionList/FirstSectionList';
import {loadFirstSectionNews} from '../../redux/FirstSection/action';
import { connect } from "react-redux";

class FirstSection extends Component{
    componentDidMount() {
        this.props.loadFirstSectionNews({serialNo : 1});
    }
    render() {
        return (
            <Fragment>
                <section className="bg_gray">
                    <FirstSectionFeature />
                    <FirstSectionList />
                </section>
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    loadFirstSectionNews: (serialNo) => dispatch(loadFirstSectionNews(serialNo))
});

export default connect(
    null,
    mapDispatchToProps
)(FirstSection);