import {put, call, takeEvery} from "redux-saga/effects";

import { setVerifyStatus,setError } from "./action";
import { SUBSCRIPTION_VERIFY } from "./actionTypes";
import { fetchSubscriptionVerify } from "../../api/index";

export function* handleSubscriptionVerifyLoad(action) {
    try {
        const { token } = action.token;
        const status = yield call(fetchSubscriptionVerify, token);
        yield put(setVerifyStatus(status));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchLatestNewsLoad() {
    yield takeEvery(SUBSCRIPTION_VERIFY.LOAD, handleSubscriptionVerifyLoad);
}
