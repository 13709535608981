import {NEWS_DETAIL_SLUG} from "./actionTypes";

const INITIAL_STATE = {
    newsDetailSlug : []
};

const latestNewsDetailSlubReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case NEWS_DETAIL_SLUG.LOAD_SUCCESS :
            return {
                ...state,
                newsDetailSlug : action.payload
            };
        default :
            return state;
    }
};

export default latestNewsDetailSlubReducer;
