import React, {Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom';
import './NewsDetailContent.css';

class NewsDetailContent extends Component{
    render() {
        const {newsDetail} = this.props;

        return (
            <Fragment>
                <h3>{ newsDetail.title }</h3>
                <p>{newsDetail.reporter_info} {newsDetail.published_time ? `- আপডেট ${newsDetail.published_time}` : ''}</p>
                <div className="inner_banner">
                    <img className="details-image" src={ newsDetail.image.details_image } alt={newsDetail.title}/>
                </div>
                <div className="content-text" dangerouslySetInnerHTML={{__html: newsDetail.description}} />

            </Fragment>
        )
    }
};

export default withRouter(NewsDetailContent);