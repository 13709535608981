import {LATEST_JOB} from "./actionTypes";

const INITIAL_STATE = {
    jobs: []
};

const jobsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LATEST_JOB.LOAD_SUCCESS :
            return {
                ...state,
                jobs: action.payload
            };
        default :
            return state;
    }
};

export default jobsReducer;
