import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {loadLatestJob} from '../../redux/latestJob/action';
import {selectJobItems} from '../../redux/latestJob/latestJob.selector';
import SingleJob from '../singleJob/SingleJob';

class LatestJob extends Component{
    componentDidMount() {
        this.props.loadLatestJob({take: 5});
    }

    render() {
        const {jobs} = this.props;
        return(
            <Fragment>
                <div className="col-sm-6">
                    <div className="content_title">
                        <h3>
                            লেটেস্ট জবস
                        </h3>
                    </div>
                    <div className="new_technology">
                        {jobs ? jobs.map(job => <SingleJob key={job.id} job={job}/>) : '' }
                    </div>
                </div>
            </Fragment>
        )
    }
};

const mapStateToProps = createStructuredSelector({
    jobs : selectJobItems
});

const mapDispatchToProps = dispatch => ({
    loadLatestJob: (take) => dispatch(loadLatestJob(take))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LatestJob);