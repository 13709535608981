import React, {Fragment} from 'react';
import {ReactComponent as UserDefaultImage} from "../../assets/comments.svg";

const Comment = ({comment}) => {
    return (
        <Fragment>
            <div className="obiroto_comments_single">
                <div className="row">
                    <div className="col-sm-1 col-md-1">
                        <div className="user_image">
                            <UserDefaultImage style={{'width' : '80px', 'height' : '65px'}} />
                        </div>
                    </div>
                    <div className="col-sm-11 col-md-11">
                        <div className="user_content">
                            <h5>{comment.user.name}</h5>
                            <h6>about {comment.created_at}</h6>
                            <p>
                                {comment.comment}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default Comment;