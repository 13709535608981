import React, {Component} from "react";
import "./App.css";
import "./Responsive.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Switch, Route} from "react-router-dom";
import Header from "./layouts/header/Header";
import Footer from "./layouts/footer/Footer";
import NewsDetail from './pages/NewsDetail/NewsDetail';
//import InterviewDetail from './pages/InterviewDetail/InterviewDetail';
import JobDetail from './pages/JobDetail/JobDetail';
import Categories from './pages/Categories/Categories';
import {auth} from './firebase/firebase.utils';
import {connect} from 'react-redux';
import {loadUser, setCurrentUser} from './redux/user/action';
import Search from './pages/Search/Search';
import Message from './pages/Success/Success';
import SubscriptionVerification from './pages/Verification/SubscriptionVerification';
import userVerification from './pages/Verification/UserVerification';
import NewsDetailSlug from './pages/NewsDetailSlug/NewsDetailSlug';
import {createStructuredSelector} from 'reselect';
import {selectUserItem} from './redux/user/user.selector';
import axios from 'axios';
import {BASE_URL} from './constants/index';
import EmptyContent from "./components/EmptyComponent/EmptyComponent";
import PhotoGallery from "./pages/PhotoGallery/PhotoGallery";
import VideoGallery from "./pages/VideoGallery/VideoGallery";
import Tags from "./pages/Tags/Tags";
import Jobs from "./pages/Job/Job";

import Home from './pages/Home/Home';

class App extends Component {
    unSubscribeFromAuth = null;

    componentDidMount() {
        const {loadUser, dispatch} = this.props;
        loadUser();
        const {setCurrentUser} = this.props;
        this.unSubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
            //console.log(userAuth);
            if (userAuth) {
                const {displayName, email} = userAuth;
                axios.post(`${BASE_URL}/social_attempt`, {name : displayName, email : email})
                    .then(res => {
                        dispatch(setCurrentUser(res.data));
                    })
                    .catch(error => console.log(error));
            }
        });

        //FB Page Load
    }

    componentWillUnmount() {
        this.unSubscribeFromAuth();
    }



    render() {
        //const {currentUser} = this.props;
        return (
            <div>
                <Header />
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route exact path='/search/:value' component={Search}/>
                    <Route exact path='/success' component={Message}/>
                    <Route
                        exact
                        path='/news/:category/:newsId/:slug'
                        component={NewsDetail}
                    />
                    <Route exact path='/:slug' component={NewsDetailSlug}/>
                    <Route exact path='/category/:category' component={Categories}/>
                    <Route exact path='/jobs/:id/:slug' component={JobDetail}/>
                    <Route exact path='/subscriber_verification/:token' component={SubscriptionVerification}/>
                    <Route exact path='/user_verification/:token' component={userVerification}/>
                    <Route exact path='/photo/gallery' component={PhotoGallery}/>
                    <Route exact path='/video/gallery' component={VideoGallery}/>
                    <Route exact path='/tags/:slug/news' component={Tags}/>
                    <Route exact path='/latest/jobs' component={Jobs}/>
                    <Route path='*' exact={true} component={EmptyContent} />
                </Switch>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectUserItem
});

const mapDispatchToProps = dispatch => ({
    loadUser: () => dispatch(loadUser()),
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
