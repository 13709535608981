import React from "react";
import Menu from "../../components/menu/Menu";

const NavContent = () => {
    return (
        <div>
            <div className='nav_content'>
                <div className='menu_sec row'>
                    <div className='col-md-12'>
                        <Menu />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default NavContent;
