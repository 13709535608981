import { NEWS_DETAIL } from "./actionTypes";

const loadNewsDetail = (newsId) => ({
    type: NEWS_DETAIL.LOAD,
    newsId
});

const setNewsDetail = newsDetail => ({
    type: NEWS_DETAIL.LOAD_SUCCESS,
    newsDetail
});

const setError = error => ({
    type: NEWS_DETAIL.LOAD_FAIL,
    error
});


export {loadNewsDetail, setNewsDetail, setError};
