import React, {Fragment} from 'react';
import './PopularList.css';
import { withRouter } from 'react-router-dom';
import LongText from '../../utilities/LongText';

const popularList = ({popularNewsList,history, match}) => {
    return (
        <Fragment>
            <div className="popular_single">
                <div className="popular_menu_list">
                    <ul>
                        {popularNewsList.map(news => (
                            <li key={news.id}  onClick={() =>
                                history.push(
                                    `${match.url}news/${news.categories[0].slug}/${news.id}/${news.slug}`
                                )
                            }>
                                <LongText content={news.title} limit='45'/>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Fragment>
    )
};

export default withRouter(popularList);