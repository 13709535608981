import { ARCHIVE } from "./actionTypes";

const loadArchive = (take) => ({
    type: ARCHIVE.LOAD,
    take
});

const setArchive = archives => ({
    type: ARCHIVE.LOAD_SUCCESS,
    payload : archives
});

const setError = error => ({
    type: ARCHIVE.LOAD_FAIL,
    error
});

export {loadArchive, setArchive, setError};
