import { ADS } from "./actionTypes";

const loadAds = () => ({
    type: ADS.LOAD
});

const setAds = ads => ({
    type: ADS.LOAD_SUCCESS,
    payload : ads
});

const setError = error => ({
    type: ADS.LOAD_FAIL,
    error
});


export {loadAds, setAds, setError};
