import {LATEST_FEATURE_NEWS} from "./actionTypes";
const loadLatestFeatureNews = () => ({
    type: LATEST_FEATURE_NEWS.LOAD
});

const setLatestFeatureNews = latestFeatureNews => ({
    type: LATEST_FEATURE_NEWS.LOAD_SUCCESS,
    payload : latestFeatureNews
});

const setLatestFetureError = error => ({
    type: LATEST_FEATURE_NEWS.LOAD_FAIL,
    error
});


export {loadLatestFeatureNews, setLatestFeatureNews, setLatestFetureError};
