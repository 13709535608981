import React, {Fragment} from 'react';
import Technology from '../Technology/Technology';
import ArchiveLatestJob from '../ArchiveLatestJob/ArchiveLatestJob';


const TechnologyArchive = () => {
    return (
        <Fragment>
            <section className="bg_white">
                <div className="container">
                    <Technology/>
                    <ArchiveLatestJob/>
                </div>
            </section>
        </Fragment>
    )
};

export default TechnologyArchive;