import {SIXTH_SECTION_LEFT_NEWS, SIXTH_SECTION_RIGHT_NEWS} from "./actionTypes";

const loadSixthSectiontLeftNews = (serialNo) => ({
    type: SIXTH_SECTION_LEFT_NEWS.LOAD,
    serialNo
});

const setSixthSectiontLeftNews = news => ({
    type: SIXTH_SECTION_LEFT_NEWS.LOAD_SUCCESS,
    payload : news
});

const setLeftError = error => ({
    type: SIXTH_SECTION_LEFT_NEWS.LOAD_FAIL,
    error
});

const loadSixthSectiontRightNews = (serialNo) => ({
    type: SIXTH_SECTION_RIGHT_NEWS.LOAD,
    serialNo
});

const setSixthSectiontRightNews = news => ({
    type: SIXTH_SECTION_RIGHT_NEWS.LOAD_SUCCESS,
    payload : news
});

const setRighttError = error => ({
    type: SIXTH_SECTION_RIGHT_NEWS.LOAD_FAIL,
    error
});


export {loadSixthSectiontLeftNews, setSixthSectiontLeftNews, setLeftError, setSixthSectiontRightNews, loadSixthSectiontRightNews, setRighttError};
