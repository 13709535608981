import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import SingleList from './FirstSectionSingle';
import {createStructuredSelector} from 'reselect';
import {selectFirstSectionListItems} from '../../../redux/FirstSection/FirstSection.selector';
import './FirstSectionList.css';


class FirstSectionList extends Component {
    render() {
        const {firstSectionListNews} = this.props;
        return (
            <Fragment>
                <div className="container">
                    <div className="banner_section_bottom">
                        <div className="row">
                            {firstSectionListNews.map((news) => (
                                <SingleList key={news.id} news={news}/>
                            ))}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector ({
    firstSectionListNews : selectFirstSectionListItems
});


export default connect(
    mapStateToProps,
    null
)(FirstSectionList);
