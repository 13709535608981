import {put, call, takeEvery} from "redux-saga/effects";

import { setNewsDetail,setError } from "./action";
import { NEWS_DETAIL } from "./actionTypes";
import { fetchNewsDetail } from "../../api/index";

export function* handleNewsDetailLoad(action) {
    try {
        const { newsId } = action.newsId;
        const newsDetail = yield call(fetchNewsDetail, newsId);
        //console.log(newsDetail);
        yield put(setNewsDetail(newsDetail));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchLatestNewsLoad() {
    yield takeEvery(NEWS_DETAIL.LOAD, handleNewsDetailLoad);
}
