import { MENUES } from "./actionTypes";

const loadingReducer = (state = false, action) => {
  switch (action.type) {
    case MENUES.LOAD:
      return true;
    case MENUES.LOAD_SUCCESS:
      return false;
    case MENUES.LOAD_FAIL:
      return false;

    default:
      return state;
  }
};

export default loadingReducer;
