import {put, call, takeEvery} from "redux-saga/effects";

import { setInterviewDetail,setError } from "./action";
import { INTERVIEW_DETAIL } from "./actionTypes";
import { fetchInterviewDetail } from "../../api/index";

export function* handleInterviewDetailLoad(action) {
    try {
        const { id } = action.id;
        const detail = yield call(fetchInterviewDetail, id);
        yield put(setInterviewDetail(detail));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchLatestInterviewLoad() {
    yield takeEvery(INTERVIEW_DETAIL.LOAD, handleInterviewDetailLoad);
}
