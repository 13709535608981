import { INTERVIEW } from "./actionTypes";

const INITIAL_STATE = {
    interviews : []
};

const interviewsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case INTERVIEW.LOAD_SUCCESS :
            return {
                ...state,
                interviews : action.payload
            };
        default :
            return state;
    }
};

export default interviewsReducer;
