import {put, call, takeEvery} from "redux-saga/effects";

import {setArchive, setError} from "./action";
import { ARCHIVE } from "./actionTypes";
import {fetchArchives} from "../../api/index";

export function* handleArchivesLoad(action) {
    try {
        //const { take } = action.take;
        const archives = yield call(fetchArchives, 3);
        yield put(setArchive(archives));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}


export default function* watchArchivesLoad() {
    yield takeEvery(ARCHIVE.LOAD, handleArchivesLoad);
}
