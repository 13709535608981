import {createSelector} from 'reselect';

const selectThirdSection = (state) => state.thirdSectionNews;

export const selectThirdSectionNewsItems = createSelector(
    [selectThirdSection],
    thirdSectionNews => thirdSectionNews.thirdSectionNews
);

export const selectThirdSectionFeature = createSelector(
    [selectThirdSection],
    thirdSectionNews => thirdSectionNews.thirdSectionNews.filter((news, index) => index < 2)
);

export const selectThirdSectionList = createSelector(
    [selectThirdSection],
    thirdSectionNews => thirdSectionNews.thirdSectionNews.filter((news, index) => index >= 2)
);