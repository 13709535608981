import React, {Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import LongText from '../../utilities/LongText';
import './CommonListNews.css';

const CommonListNews = ({news, history, match, textLimit}) => {
    return (
        <Fragment>
            <div className="interview_single entertainment_single" onClick={() =>
                history.push(
                    `${match.url}news/${news.categories[0].slug}/${news.id}/${news.slug}`
                )
            }>
                <img src={news.image ? news.image.list_image : ''} alt={news.title}/>
                <div className="interview_single_content">
                    <h5>
                        {news.title}
                    </h5>
                    <p>
                        <LongText content={news.short_description} limit={textLimit}/>
                    </p>
                </div>
            </div>
        </Fragment>
    )
};

export default withRouter(CommonListNews);