import { SETTING } from "./actionTypes";

const loadSetting = () => ({
    type: SETTING.LOAD,
});

const setSetting = setting => ({
    type: SETTING.LOAD_SUCCESS,
    payload : setting
});

const setError = error => ({
    type: SETTING.LOAD_FAIL,
    error
});


export {loadSetting, setSetting, setError};
