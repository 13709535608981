import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectSettingItem} from "../../redux/setting/setting.selector";
import FormInput from "../../components/form-input/FormInput";
import {withRouter, Link} from 'react-router-dom';
import {convertToSlug} from '../../utilities/Slugify';
import './SocialAndSearch.css';
import firebase from 'firebase/app';
import {logOutUser} from '../../redux/user/action';
import {selectUserItem} from '../../redux/user/user.selector';

class NavContent extends Component {
    constructor(porps) {
        super();
        this.state = {
            search: ''
        }
    }

    handleChange = event => {
        const {value, name} = event.target;
        this.setState({[name]: value});
    };

    handleSubmit = event => {
        event.preventDefault();
        const {history, match} = this.props;
        const {search} = this.state;
        this.setState({search : ''});
        history.push(`${match.url}search/${convertToSlug(search)}`);
    };

    logout = () => {
        firebase.auth().signOut();
        this.props.logOutUser();
    };

    render() {
        const {setting, currentUser} = this.props;
        return (
            <Fragment>
                <div className='col-md-12 icon_search'>
                    <div className='social_icon'>
                        <a href={setting.fb_link} target="_blank" rel="noopener noreferrer">
                            <i className='fa fa-facebook'/>
                        </a>
                        <a href={setting.twitter_link} target="_blank" rel="noopener noreferrer">
                            <i className='fa fa-twitter'/>
                        </a>
                        <a href={setting.youtube_link} target="_blank" rel="noopener noreferrer">
                            <i className='fa fa-youtube'/>
                        </a>
                        <a href={setting.pinterest_link} target="_blank" rel="noopener noreferrer">
                            <i className='fa fa-pinterest-square'/>
                        </a>
                    </div>
                    <div className='search_navbar'>
                        <form className='search-form' onSubmit={this.handleSubmit}>
                            <div className='search-field-container'>
                                <FormInput className='search-field' name='search' type='text'
                                           placeholder="" value={this.state.search}
                                           required handleChange={this.handleChange}/>
                                <i onClick={this.handleSubmit} className="fa fa-search searchIcon" aria-hidden="true"/>
                            </div>
                        </form>
                    </div>
                    {currentUser ? (<Link to="/">
                        <span className="sign-out" onClick={this.logout}><i className="fa fa-user" aria-hidden="true"/>&nbsp; সাইন আউট</span>
                    </Link>) : ''}

                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    setting: selectSettingItem,
    currentUser: selectUserItem
});

const mapDispatchToProps = dispatch => ({
    logOutUser: () => dispatch(logOutUser()),
    dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavContent));
