import React, {Fragment} from 'react';
import './Image.css';

const Image = ({ad}) => {
    return (
        <Fragment>
            <a target="_blank" href={ad.link} rel="noopener noreferrer">
                <img
                    src={ad.image}
                    className='img-responsive'
                    alt='Top Banner'
                />
            </a>
        </Fragment>
    )
};

export default Image;