import {createSelector} from 'reselect';

const selectSixthLeftSection = (state) => state.sixthSectionLeftNews;
const selectSixthRightSection = (state) => state.sixthSectionRightNews;

export const selectSixthSectionNewsItems = createSelector(
    [selectSixthLeftSection,selectSixthRightSection],
    (sixthSectionLeftNews,sixthSectionRightNews) => ({
        sixthSectionLeftNews : sixthSectionLeftNews.sixthSectionLeftNews,
        sixthSectionRightNews : sixthSectionRightNews.sixthSectionRightNews,
    })
);

