import { JOB_DETAIL } from "./actionTypes";

const loadJobDetail = (id) => ({
    type: JOB_DETAIL.LOAD,
    id
});

const setJobDetail = jobDetail => ({
    type: JOB_DETAIL.LOAD_SUCCESS,
    payload : jobDetail
});

const setError = error => ({
    type: JOB_DETAIL.LOAD_FAIL,
    error
});


export {loadJobDetail, setJobDetail, setError};
