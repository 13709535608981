import React, {Fragment, Component} from 'react';
import CommonMainFeature from '../commonComponent/CommonMainFeature';
import CommonListNews from '../commonComponent/CommonListNews';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {loadFourthSectionNews} from '../../redux/fourthSection/action';
import {selectFourthSectionNewsItems} from '../../redux/fourthSection/fourthSection.selector';
import {withRouter} from 'react-router-dom';

class FourthNewsSection extends Component {
    componentDidMount() {
        this.props.loadFourthSectionNews({serialNo: 5});
    }

    render() {
        const {fourthSections, history, match} = this.props;
        return (
            <Fragment>
                {fourthSections.length > 0 ? (
                    <section className="bg_white">
                        <div className="container">
                            <div className="technology_section">
                                <div className="content_title">
                                    <h3 className="category_title" onClick={() => history.push(`${match.url}category/${fourthSections[0].categories[0].slug}`)}>
                                        {fourthSections[0].categories[0].title}
                                    </h3>
                                </div>
                                <div className="row">
                                    <Fragment>
                                        <div className="col-md-4">
                                            <CommonMainFeature news={fourthSections[0]}/>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                {fourthSections.filter((news, index) => index > 0).map((news, index) => (
                                                    <div className="col-md-6" key={news.id}>
                                                        <CommonListNews key={news.id} textLimit="30" news={news}/>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Fragment>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : ''}
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    fourthSections: selectFourthSectionNewsItems
});

const mapDispatchToProps = dispatch => ({
    loadFourthSectionNews: (serialNo) => dispatch(loadFourthSectionNews(serialNo))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FourthNewsSection));