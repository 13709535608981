import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {loadNews} from '../../redux/popularNews/action';
import PopularFeature from '../popularFeature/PopularFeature';
import PopularList from '../popularList/PopularList';
import {createStructuredSelector} from 'reselect';
import {selectPopularFeature, selectPopularList} from '../../redux/popularNews/popularNews.selectior';
import Image from '../Image/Image';
import {selectAdsItems} from '../../redux/Ads/ads.selector';

class PopularNews extends Component {
    componentDidMount() {
        this.props.loadNews({take: 6});
    }

    render() {
        const {popularFeatureNews, popularListNews, ads} = this.props;
        return (
            <Fragment>
                <section className="bg_gray">
                    <div className="container">
                        <div className="popular_section">
                            <div className="content_title">
                                <h3>
                                    সর্বাধিক জনপ্রিয়
                                </h3>
                            </div>
                            <div className="row">
                                {popularFeatureNews ? popularFeatureNews.map(news => <PopularFeature key={news.id} news={news} textLimit="40"/>): ''}

                                <div className="col-md-3">
                                    {popularListNews ? <PopularList popularNewsList={popularListNews}/> : '' }
                                </div>

                                <div className="col-md-3">
                                    <div className="popular_ad">
                                        {ads.filter((ad, index) => index === 4).map(ad =>
                                            <Image ad={ad} key={ad.id}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    popularFeatureNews: selectPopularFeature,
    popularListNews: selectPopularList,
    ads: selectAdsItems
});

const mapDispatchToProps = dispatch => ({
    loadNews: (take) => dispatch(loadNews(take))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PopularNews);