import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyBzfPXiNaW9Q5JOzK7pkTQHueWHY-YnUnQ",
    authDomain: "obiroto-login.firebaseapp.com",
    databaseURL: "https://obiroto-login.firebaseio.com",
    projectId: "obiroto-login",
    storageBucket: "obiroto-login.appspot.com",
    messagingSenderId: "523621808428",
    appId: "1:523621808428:web:81a58f0f05932726209489",
    measurementId: "G-160QEQX41R"
};

export const createUserProfileDocument = async (userAuth, additionalData) => {
    //console.log(userAuth);
    if(!userAuth) return;

    const userRef = firestore.doc(`users/${userAuth.uid}`);
    const snapShot = await userRef.get();
    if (!snapShot.exists) {
        const { displayName, email } = userAuth;
        console.log(displayName);
        const createdAt = new Date();

        try {
            await userRef.set({
                displayName,
                email,
                createdAt,
                ...additionalData
            })
        } catch (e) {
            console.log('error creating user', e.message);
        }
    }
    return userRef;
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

const Provider = new firebase.auth.GoogleAuthProvider();
Provider.setCustomParameters({ prompt : 'select_account'});
export const signInWithGoogle = () => auth.signInWithPopup(Provider);
export const signOut = () => {
    firebase.auth().signOut();
    localStorage.clear();
    console.log('success');
};

export default firebase;