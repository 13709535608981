import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {subscriptionCheck} from '../../redux/subscriptionVerification/action';
import {withRouter} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';
import {selectSubscriptionStatusItem} from '../../redux/subscriptionVerification/subscriptionVerify.selector';
import Message from '../Success/Success';

class SubscriptionVerification extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        const {match} = this.props;
        const {token} = match.params;
        this.props.subscriptionCheck({token: token});
    }

    render() {
        const {status} = this.props;
        return (
            <Fragment>
                {status === 'success' ?
                    <Message message="অভিনন্দন !! আপনি সফলভাবে সম্পন্ন করেছেন।"/>
                    : <Message message="দুঃখিত !! টোকেন সঠিক না।" icon="fa fa-times error"/>}
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    status: selectSubscriptionStatusItem
});

const mapDispatchToProps = dispatch => ({
    subscriptionCheck: (token) => dispatch(subscriptionCheck(token)),
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SubscriptionVerification));