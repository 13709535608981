import { LATEST_LIST_NEWS } from "./actionTypes";

const latestListNewsReducer = (state = [], action) => {
  if (action.type === LATEST_LIST_NEWS.LOAD_SUCCESS) {
    return [...state, ...action.latestListNews];
  }
  return state;
};

export default latestListNewsReducer;
