import {INTERVIEW_DETAIL} from "./actionTypes";

const INITIAL_STATE = {
    interviewDetail : []
};

const latestInterviewDetailReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case INTERVIEW_DETAIL.LOAD_SUCCESS :
            return {
                ...state,
                interviewDetail : action.payload
            };
        default :
            return state;
    }
};

export default latestInterviewDetailReducer;
