import React,{Component, Fragment} from 'react';
import SignInModal from '../../components/modals/SignInModal';
import SignUpModal from '../../components/modals/SignUpModal';

class SignInSignUp extends Component {
    render() {
        return(
            <Fragment>
                <div className="login_signin">
                    <p>Sign Up Or Login to comments</p>
                    <SignInModal/>
                    <SignUpModal />
                </div>
            </Fragment>
        )
    }
}

export default SignInSignUp;