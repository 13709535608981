import { GALLERY } from "./actionTypes";

const INITIAL_STATE = {
    galleries: []
};

const galleriesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GALLERY.LOAD_SUCCESS :
            return {
                ...state,
                galleries: action.payload
            };
        default :
            return state;
    }
};

export default galleriesReducer;
