import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import './Interview.css';
import LongText from '../../utilities/LongText';

const Interview = ({interview, isShowDetais, history, match}) => {
    return (
        <Fragment>
            <div className="interview_single" onClick={() =>
                history.push(
                    `/news/${interview.categories[0].slug}/${interview.id}/${interview.slug}`
                )
            }>
                <img className="details-image" src={ interview.image ? interview.image.list_image : '' } alt={interview.title}/>
                <div className="interview_single_content">
                    <h5>
                        <LongText content={interview.title} limit='40'/>
                    </h5>
                    <span>{interview.published_time}</span>
                    <div>
                        {isShowDetais ?  <LongText content={interview.short_description} limit='50'/> : ''}
                    </div>
                </div>
            </div>
        </Fragment>
    )
};


export default withRouter(Interview);
