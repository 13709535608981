import React, {Component, Fragment} from 'react';
import {Modal, Button, Spinner} from 'react-bootstrap';
import {signInWithGoogle} from '../../firebase/firebase.utils';
import FormInput from "../form-input/FormInput";
import {BASE_URL} from '../../constants/index';
import axios from 'axios';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {setCurrentUser} from '../../redux/user/action';
import {selectUserItem} from '../../redux/user/user.selector';
import ToastMessage from '../../components/notify/Toast';

class SignInModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            email: "",
            password: "",
            isLoading: false,
            error: false,
            message: ""
        };
        this.wrapper = React.createRef();
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState(prevState => ({
            isLoading: !prevState.isLoading
        }));

       const {dispatch} = this.props;
        try {
            const data = {
                email: this.state.email,
                password: this.state.password
            };

            axios.post(`${BASE_URL}/login`, data)
                .then(res => {
                    this.setState(prevState => ({
                        isLoading: !prevState.isLoading,
                        message: 'Successfully Sign In !!'
                    }));
                    if (res.data.success) {
                        this.setState(prevState => ({
                            message: 'Successfully Sign In !!'
                        }));
                        dispatch(setCurrentUser(res.data.data));
                    } else {
                        this.setState(prevState => ({
                            error: !prevState.error,
                            message: res.data.message
                        }));
                    }
                    this.handleClose();
                })
                .catch(error => {
                    this.setState(prevState => ({
                        isLoading: !prevState.isLoading,
                        error: !prevState.error,
                        message: error.response.data.message
                    }));
                });


            //auth.signInWithEmailAndPassword(email, password);
            this.setState({email: "", password: ""});
        } catch (e) {
            console.error(e);
        }
    };

    handleChange = event => {
        const {value, name} = event.target;
        this.setState({[name]: value});
    };

    handleClose = () => this.setState({show: false});
    handleShow = () => this.setState({show: true});

    render() {
        const {isLoading, message, error, show} = this.state;
        //const {user} = this.props;
        return (
        <Fragment>
            <Button variant="primary" onClick={this.handleShow}>
                Sign In
            </Button>

            <Modal ref={this.wrapper} show={show} onHide={this.handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>SIGN IN</Modal.Title>
                </Modal.Header>
                <form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        <div className="social_login">
                            <Button onClick={signInWithGoogle} className="btn btn-danger btn-block text-white">
                                <i className="fa fa-google-plus"/>
                                LOG IN WITH GOOGLE
                            </Button>
                        </div>
                        <p>
                            Or sign up using email
                        </p>
                        <FormInput name='email' type='email' placeholder="Email Address" value={this.state.email}
                                   required handleChange={this.handleChange}/>
                        <FormInput name='password' type='password' placeholder="Password" value={this.state.password}
                                   required handleChange={this.handleChange}/>
                    </Modal.Body>
                    <Modal.Footer>
                        {isLoading ? ( <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            Process...
                        </Button>) : (
                            <Fragment>
                                <Button variant="secondary" onClick={this.handleClose}>
                                    Close
                                </Button>
                                <Button type="submit" variant="primary">
                                    Submit
                                </Button>
                            </Fragment>
                        )}
                    </Modal.Footer>
                </form>
            </Modal>
            {message ? <ToastMessage isShow message={message} type={error ? 2 : 1}/> : ''}
        </Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    user: selectUserItem
});

const mapDispatchToProps = dispatch => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignInModal);