import { SEARCH } from "./actionTypes";

const INITIAL_STATE = {
    searches : []
};

const searchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEARCH.LOAD_SUCCESS :
            return {
                ...state,
                searches: action.payload
            };
        default :
            return state;
    }
};

export default searchReducer;
