import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {loadJobDetail} from '../../redux/jobDetails/action';
import Sidebar from '../../components/sidebar/Sidebar';
import {withRouter} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';
import {selectJobDetailItem} from '../../redux/jobDetails/jobDetails.selector';
//import ShareThis from '../../components/socialSharing/ShareThis';
import Sharing from '../../components/socialSharing/Sharing';
import './JobDetail.css';

class JobDetail extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        const {match} = this.props;
        const {id} = match.params;
        this.props.loadJobDetail({id: id});
    }

    render() {
        const {detail} = this.props;
        if (typeof window !== 'undefined') {
            var sharePath = window.location.protocol + '//' + window.location.host + window.location.pathname;
        }
        return (
            <Fragment>
                <section className="bg_white">
                    <div className="banner_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="inner_content">
                                        {detail ? (
                                            <Fragment>
                                                <h3>
                                                    <img src={detail.logo} alt={detail.title} className="logo"/>
                                                    { detail.title }
                                                </h3>
                                                <h4>{ detail.designation }</h4>
                                                <p>পাবলিশড { detail.created_at }</p>
                                                <div className="content-text" dangerouslySetInnerHTML={{__html: detail.description}}/>
                                            </Fragment>
                                        ) : ''}
                                        <Sharing shareUrl={sharePath}/>
                                        {/*<ShareThis
                                            url={sharePath}
                                            title={detail.title}
                                            shortDescription={detail.designation}
                                            image={detail.logo}
                                        />*/}
                                    </div>
                                </div>
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    detail: selectJobDetailItem
});

const mapDispatchToProps = dispatch => ({
    loadJobDetail: (id) => dispatch(loadJobDetail(id)),
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(JobDetail));