import React, {Fragment, Component} from "react";
import NewsTickers from "../news-ticker/NewsTickers";
import TopBar from "../top-bar/TopBar";
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {loadNews} from '../../redux/news/action';
import {selectNewsCollection} from '../../redux/news/news.selector';
import {loadSetting} from '../../redux/setting/action';

class Header extends Component {
    componentDidMount() {
        //this.props.loadNews();
        this.props.loadSetting();
    }

    render() {
        return (
            <Fragment>
                <header>
                    <NewsTickers />
                    <TopBar />
                </header>
            </Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    news: selectNewsCollection
});

const mapDispatchToProps = (dispatch) => ({
    loadNews: () => dispatch(loadNews()),
    loadSetting: () => dispatch(loadSetting()),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
