import {put, takeEvery} from "redux-saga/effects";

import { setCurrentUser,setError } from "./action";
import { USER  } from "./actionTypes";

export function* handleUserLoad(action) {
    try {
        yield put(setCurrentUser(action.user));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}

export function* handleUserLogout(action) {
    try {
        yield put(setCurrentUser(''));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchUserLoad() {
    yield takeEvery(USER.LOAD, handleUserLoad);
    yield takeEvery(USER.LOG_OUT, handleUserLogout);
}
