import { USER_VERIFY } from "./actionTypes";

const userVerifyErrorReducer = (state = null, action) => {
  switch (action.type) {
    case USER_VERIFY.LOAD_FAIL:
      return action.error;
    case USER_VERIFY.LOAD:
    case USER_VERIFY.LOAD_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default userVerifyErrorReducer;
