import { POPULAR_NEWS } from "./actionTypes";

const loadNews = (take) => ({
    type: POPULAR_NEWS.LOAD,
    take
});

const setNews = popularNews => ({
    type: POPULAR_NEWS.LOAD_SUCCESS,
    payload : popularNews
});

const setError = error => ({
    type: POPULAR_NEWS.LOAD_FAIL,
    error
});


export {loadNews, setNews, setError};
