import {put, call, takeEvery} from "redux-saga/effects";

import {setSixthSectiontLeftNews, setSixthSectiontRightNews, setLeftError, setRighttError} from "./action";
import {SIXTH_SECTION_LEFT_NEWS, SIXTH_SECTION_RIGHT_NEWS} from "./actionTypes";
import {fetchNews} from "../../api/index";

export function* handleSixthSectionLeftNewsLoad(action) {
    try {
        const { serialNo } = action.serialNo;
        const news = yield call(fetchNews, serialNo, 4, null);
        yield put(setSixthSectiontLeftNews(news));
    } catch (error) {
        yield put(setLeftError(error.toString()));
    }
}

export function* handleSixthSectionRightNewsLoad(action) {
    try {
        const { serialNo } = action.serialNo;
        const news = yield call(fetchNews, serialNo, 4, null);
        yield put(setSixthSectiontRightNews(news));
    } catch (error) {
        yield put(setRighttError(error.toString()));
    }
}



export default function* watchSixthSectionNewsLoad() {
    yield takeEvery(SIXTH_SECTION_LEFT_NEWS.LOAD, handleSixthSectionLeftNewsLoad);
    yield takeEvery(SIXTH_SECTION_RIGHT_NEWS.LOAD, handleSixthSectionRightNewsLoad);
}
