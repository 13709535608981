import {put, call, takeEvery} from "redux-saga/effects";

import { setSearch,setError } from "./action";
import { SEARCH } from "./actionTypes";
import { fetchSearch } from "../../api";

export function* handleSearchLoad(action) {
    try {
        const { search } = action.search;
        const values = yield call(fetchSearch, search);
        yield put(setSearch(values));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchSearchLoad() {
    yield takeEvery(SEARCH.LOAD, handleSearchLoad);
}
