import {put, call, takeEvery} from "redux-saga/effects";

import {setFifthRightSectionNews, setError} from "./action";
import {FIFTH_RIGHT_SECTION_NEWS} from "./actionTypes";
import {fetchNews} from "../../../api/index";

export function* handleFifthRightSectionNewsLoad(action) {
    try {
        const { serialNo } = action.serialNo;
        const news = yield call(fetchNews, serialNo, 4, null);
        yield put(setFifthRightSectionNews(news));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}


export default function* watchFifthRightSectionNewsLoad() {
    yield takeEvery(FIFTH_RIGHT_SECTION_NEWS.LOAD, handleFifthRightSectionNewsLoad);
}
