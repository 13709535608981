import React, {Fragment} from 'react';
import LeftSection from './Left/Left';
import RightSection from './Right/Right';

const SecondSection = () => {
    return(
        <Fragment>
            <section className="bg_white">
                <div className="container">
                    <div className="entrepreneurship_section">
                        <div className="row">
                           <LeftSection/>
                           <RightSection/>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

export default SecondSection;