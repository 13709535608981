import {BASE_URL} from "../constants";

const fetchMenues = async page => {
    const response = await fetch(`${BASE_URL}/categories`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchCategoryWiseNews = async () => {
    const response = await fetch(`${BASE_URL}/category-wise-news-load`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchNews = async (id, take, skip) => {
    const response = await fetch(`${BASE_URL}/category/${id}/news?take=${take}&skip=${skip}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchNewsDependOnCategory = async (category, pageNumber) => {
    const response = await fetch(`${BASE_URL}/${category}/news?page=${pageNumber}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchNewsDependOnTag = async (slug , pageNumber) => {
    const response = await fetch(`${BASE_URL}/tags/${slug}/news?page=${pageNumber}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchRelatedNews = async (category, except, take) => {
    const response = await fetch(`${BASE_URL}/related-news/${category}/${except}?take=${take}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchPopularNews = async (take) => {
    const response = await fetch(`${BASE_URL}/popular/news?take=${take}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchLatestNews = async (take) => {
    const response = await fetch(`${BASE_URL}/latest/news?take=${take}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchGalleries = async (take) => {
    const response = await fetch(`${BASE_URL}/galleries?take=${take}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchArchives = async (take) => {
    const response = await fetch(`${BASE_URL}/archive/news?take=${take}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchJobs = async (take) => {
    const response = await fetch(`${BASE_URL}/latest/jobs?take=${take}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchNewsDetail = async (id) => {
    const response = await fetch(`${BASE_URL}/news/${id}`);
    const data = await response.json();
    //console.log(data);
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchNewsDetailSlug = async (slug) => {
    const response = await fetch(`${BASE_URL}/news/detail/${slug}`);
    const data = await response.json();
    console.log(data);
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchInterviews = async (take) => {
    const response = await fetch(`${BASE_URL}/interviews?take=${take}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchYoutubeVideos = async (take) => {
    const response = await fetch(`https://www.googleapis.com/youtube/v3/search?key=AIzaSyBG38ixJYNRTtEAO2L8jFJ-pq05C5yLGHk&channelId=UCNp-1ocTzbQvdmJPSxHi8Ig&part=snippet,id&order=date&maxResults=${take}`);
    //const response = '';
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchInterviewDetail = async (id) => {
    const response = await fetch(`${BASE_URL}/interview/${id}`);
    const data = await response.json();
    //console.log(data);
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchJobDetail = async (id) => {
    const response = await fetch(`${BASE_URL}/latest/jobs/${id}`);
    const data = await response.json();
    //console.log(data);
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchAds = async () => {
    const response = await fetch(`${BASE_URL}/ads`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchSetting = async () => {
    const response = await fetch(`${BASE_URL}/setting`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchSearch = async (search) => {
    const response = await fetch(`${BASE_URL}/search?q=${search}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchSubscriptionVerify = async (token) => {
    const response = await fetch(`${BASE_URL}/subscriber_verification/${token}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchUserVerify = async (token) => {
    const response = await fetch(`${BASE_URL}/user_verification/${token}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};


export {
    fetchMenues,
    fetchCategoryWiseNews,
    fetchNews,
    fetchInterviews,
    fetchNewsDetail,
    fetchPopularNews,
    fetchAds,
    fetchInterviewDetail,
    fetchArchives,
    fetchJobs,
    fetchJobDetail,
    fetchSetting,
    fetchSearch,
    fetchRelatedNews,
    fetchSubscriptionVerify,
    fetchUserVerify,
    fetchNewsDetailSlug,
    fetchNewsDependOnCategory,
    fetchGalleries,
    fetchYoutubeVideos,
    fetchLatestNews,
    fetchNewsDependOnTag
};
