import {SETTING} from "./actionTypes";

const INITIAL_STATE = {
    setting : []
};

const settingReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SETTING.LOAD_SUCCESS :
            return {
                ...state,
                setting : action.payload
            };
        default :
            return state;
    }
};

export default settingReducer;
