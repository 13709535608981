import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {loadYoutube} from '../../../redux/Youtube/action';
import {selectYoutubeItems} from '../../../redux/Youtube/youtube.selectior';
import YoutubeVideo from '../../commonComponent/Youtube/Youtube';
import LongText from '../../../utilities/LongText';
import {withRouter} from 'react-router-dom';

class Youtube extends Component {
    componentDidMount() {
        this.props.loadYoutube({take: 4});
    }

    render() {
        const {youtubeVideos,history} = this.props;
        return (
            <Fragment>
                <div className="col-md-6 video_gallery">
                    <div className="content_title">
                        <h3 className="category_title" onClick={() => history.push(`/video/gallery`)}>
                            ভিডিও
                        </h3>
                    </div>
                    <div className="row">
                        {youtubeVideos ? youtubeVideos.map((video, index) => (
                            <div className="col-md-6" key={index}>
                                <div className="gallery_single">
                                    <YoutubeVideo video={video}/>
                                    <div className="gallery_des">
                                        <p><LongText content={video.snippet.title} limit='60'/></p>
                                    </div>
                                </div>
                            </div>
                        )) : ''}


                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    youtubeVideos: selectYoutubeItems,
});

const mapDispatchToProps = dispatch => ({
    loadYoutube: (take) => dispatch(loadYoutube(take))
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Youtube));