import { USER_VERIFY } from "./actionTypes";

const registerTokenCheck = (token) => ({
    type: USER_VERIFY.LOAD,
    token
});

const setVerifyStatus = status => ({
    type: USER_VERIFY.LOAD_SUCCESS,
    payload : status
});

const setError = error => ({
    type: USER_VERIFY.LOAD_FAIL,
    error
});


export {registerTokenCheck, setVerifyStatus, setError};
