import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import './SingleJob.css';

const SingleJob = ({job,history, match}) => {
    return(
        <Fragment>
            <div className="new_technology_list job_list" onClick={() =>
                history.push(
                    `${match.url}jobs/${job.id}/${job.slug}`
                )
            }>
                <img src={job.logo} alt={job.title}/>
                <h3>
                    {job.title}
                </h3>
                <p>
                    {job.designation}
                </p>
            </div>
        </Fragment>
    )
};

export default withRouter(SingleJob);