import { NEWS_DETAIL } from "./actionTypes";

const newsDetailErrorReducer = (state = null, action) => {
  switch (action.type) {
    case NEWS_DETAIL.LOAD_FAIL:
      return action.error;
    case NEWS_DETAIL.LOAD:
    case NEWS_DETAIL.LOAD_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default newsDetailErrorReducer;
