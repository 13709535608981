import {all} from "redux-saga/effects";

import menuesSaga from "./menues/menuesSaga";
import firstSectionSaga from "./FirstSection/FirstSectionSaga";
import latestListNewsSaga from "./latestList/latestListSaga";
import latestFeatureNewsSaga from "./latestFeature/latestFeatureSaga";
import interviewsSaga from "./Interview/interviewSaga";
import newsDetailsSaga from './newsDetails/newsDetailSaga';
import newsDetailsSlugSaga from './newsDetailsSlug/newsDetailSlugSaga';
import categoryNewsSaga from './categoryNews/categoryNewsSaga';
import popularNewsSaga from './popularNews/popularNewsSaga';
import userSaga from './user/userSaga';
import adsSaga from './Ads/adsSaga';
import newsSaga from './news/newsSaga';
import interviewDetail from './interviewDetails/interviewDetailSaga';
import archive from './archive/archiveSaga';
import jobs from './latestJob/latestJobSaga';
import jobDetail from './jobDetails/jobDetailSaga';
import setting from './setting/settingSaga';
import search from './search/searchSaga';
import secondSectionLeft from './secondSection/left/secondSectionLeftSaga';
import secondSectionRight from './secondSection/right/secondSectionRightSaga';
import thirdSection from './thirdSection/thirdSectionSaga';
import fourthSection from './fourthSection/fourthSectionSaga';
import fifthLeftSection from './FifthSection/Left/fifthLeftectionSaga';
import fifthMiddleSection from './FifthSection/Middle/fifthMiddleSectionSaga';
import fifthRightSection from './FifthSection/Right/fifthRightSectionSaga';
import sixthSection from './sixthSection/sixthSectionSaga';

import relatedNews from './relatedNews/relatedNewsSaga';
import subscriptionVerify from './subscriptionVerification/subscriptionVerifySaga';
import userVerify from './userVerification/userVerifySaga';
import galleries from './Gallery/gallerySaga';
import youtube from './Youtube/youtubeSaga';
import latestNews from "./latestNews/latestNewsSaga";
import tagNews from "./Tags/tagsSaga";

export default function* rootSaga() {
    yield all([
        menuesSaga(),
        newsSaga(),
        firstSectionSaga(),
        latestListNewsSaga(),
        latestFeatureNewsSaga(),
        interviewsSaga(),
        newsDetailsSaga(),
        categoryNewsSaga(),
        popularNewsSaga(),
        userSaga(),
        adsSaga(),
        interviewDetail(),
        archive(),
        jobs(),
        jobDetail(),
        setting(),
        search(),
        secondSectionLeft(),
        secondSectionRight(),
        thirdSection(),
        fourthSection(),
        fifthLeftSection(),
        fifthMiddleSection(),
        fifthRightSection(),
        sixthSection(),
        relatedNews(),
        subscriptionVerify(),
        userVerify(),
        newsDetailsSlugSaga(),
        galleries(),
        youtube(),
        latestNews(),
        tagNews()
    ]);
}
