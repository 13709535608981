import {put, call, takeEvery} from "redux-saga/effects";

import {setInterview, setError} from "./action";
import { INTERVIEW } from "./actionTypes";
import {fetchInterviews} from "../../api/index";

export function* handleInterviewLoad() {
    try {
        const interviews = yield call(fetchInterviews, 3);
        yield put(setInterview(interviews));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}


export default function* watchInterviewLoad() {
    yield takeEvery(INTERVIEW.LOAD, handleInterviewLoad);
}
