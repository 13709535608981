import React, {Fragment} from 'react';
import  {FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon} from 'react-share';


const Sharing = ({shareUrl}) => {
    return(
        <Fragment>
            <div className="share_article">
                <h3>আর্টিকেল শেয়ার করুন</h3>
                <FacebookShareButton url={shareUrl}>
                    <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton subject="new tile" description="new description" url={shareUrl}>
                    <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl}>
                    <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                {/*<PinterestShareButton url={shareUrl}>
                    <PinterestIcon size={32} round={true} />
                </PinterestShareButton>*/}
            </div>
        </Fragment>
    )
};


export default Sharing;