import {FIFTH_LEFT_SECTION_NEWS} from "./actionTypes";

const loadFifthLeftSectionNews = (serialNo) => ({
    type: FIFTH_LEFT_SECTION_NEWS.LOAD,
    serialNo
});

const setFifthLeftSectionNews = news => ({
    type: FIFTH_LEFT_SECTION_NEWS.LOAD_SUCCESS,
    payload : news
});

const setError = error => ({
    type: FIFTH_LEFT_SECTION_NEWS.LOAD_FAIL,
    error
});


export {loadFifthLeftSectionNews, setFifthLeftSectionNews, setError};
