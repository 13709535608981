import React, {Component} from "react";
import {Link} from "react-router-dom";
import {ReactComponent as Logo} from "../../assets/logo.svg";
import {connect} from 'react-redux';
import {loadAds} from '../../redux/Ads/action';
import Image from '../../components/Image/Image';
import './HeaderTop.css';
import {createStructuredSelector} from 'reselect';
import {selectAdsItems} from '../../redux/Ads/ads.selector';
import {selectSettingItem} from '../../redux/setting/setting.selector';
import SocialAndSearch from '../../components/socialAndSearch/SocialSearch';
import {Button, Spinner} from 'react-bootstrap';
import FormInput from "../../components/form-input/FormInput";
import {BASE_URL} from '../../constants/index';
import axios from '../../axios';
import ToastMessage from '../../components/notify/Toast';
import {withRouter} from "react-router-dom";

class HeaderTop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            message: "",
            isLoading: false,
            error: false
        };
    }

    componentDidMount() {
        this.props.loadAds();
    }
    handleSubmit = event => {
        event.preventDefault();
        this.setState(prevState => ({
            isLoading: !prevState.isLoading
        }));
        const {email} = this.state;
        const {history} = this.props;
        try {
            const data = {
                email: email,
            };

            axios.post(`${BASE_URL}/subscribe`, data)
                .then(res => {
                    this.setState(prevState => ({
                        isLoading: !prevState.isLoading,
                        message: 'Congratulation !! You successfully subscribed. Please Verify your Mail.'
                    }));
                    history.push('/success');
                })
                .catch(error => {
                    console.log(error.response);
                    this.setState(prevState => ({
                        isLoading: !prevState.isLoading,
                        error: !prevState.error,
                        message: error.response.data.message
                    }));
                });

            this.setState({email: ""});
        } catch (e) {
            console.error(e);
        }
    };

    handleChange = event => {
        const {value, name} = event.target;
        this.setState({[name]: value});
    };

    render() {
        const {ads,setting} = this.props;
        const {isLoading, message, error} = this.state;
        return (
            <div className='header_top_content'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='logo'>
                            <Link to='/'>
                                <Logo/>
                            </Link>
                        </div>
                        <div className='logo_content'>
                            <p>{setting && setting.todayEnglishDate ? `${setting.todayEnglishDate}, ${setting.todayBanglaDate}` : ''}</p>
                        </div>
                    </div>
                   {/* <div className='col-md-1'/>*/}
                    <div className='col-md-9'>
                        <SocialAndSearch/>
                        <div className="row">
                            <div className="col-md-8">
                                <div className='add_banner_top'>
                                    {ads ? ads.filter((ad, index) => index === 0).map(ad =>
                                        <Image ad={ad} key={ad.id}/>
                                    ) : ''}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="newsletter">
                                    <form onSubmit={this.handleSubmit}>
                                        <FormInput name='email' type='email' placeholder="ইমেইল দিয়ে সাবস্ক্রাইব করুন"
                                                   value={this.state.email}
                                                   required handleChange={this.handleChange}/>
                                        {isLoading ? ( <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </Button>) : (
                                            <Button type="submit" variant="primary">
                                                <i className="fa fa-paper-plane" aria-hidden="true"/>
                                            </Button>
                                        )}

                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {message ? <ToastMessage isShow message={message} type={error ? 2 : 1}/> : ''}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    ads : selectAdsItems,
    setting : selectSettingItem
});

const mapDispatchToProps = dispatch => ({
    loadAds: () => dispatch(loadAds())
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderTop));
