import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {loadYoutube} from '../../redux/Youtube/action';
import {selectYoutubeItems} from '../../redux/Youtube/youtube.selectior';
import YoutubeVideo from '../../components/commonComponent/Youtube/Youtube';
import Sidebar from '../../components/sidebar/Sidebar';
import './VideoGallery.css';
import LongText from '../../utilities/LongText';

class Youtube extends Component {
    componentDidMount() {
        this.props.loadYoutube({take: 25});
    }

    render() {
        const {youtubeVideos} = this.props;
        return (
            <Fragment>
                <section className="bg_white">
                    <div className="banner_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="inner_content video_gallery ">
                                        <h3>ভিডিও গ্যালারি</h3>
                                        <div className="row">
                                            {youtubeVideos ? youtubeVideos.map((video, index) => (
                                                <div className="col-sm-4" key={index}>
                                                    <div className="gallery_single">
                                                        <YoutubeVideo video={video}/>
                                                        <div className="gallery_des">
                                                            <p><LongText content={video.snippet.title} limit='60'/></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : ''}
                                        </div>
                                    </div>
                                </div>
                                <Sidebar/>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    youtubeVideos: selectYoutubeItems,
});

const mapDispatchToProps = dispatch => ({
    loadYoutube: (take) => dispatch(loadYoutube(take))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Youtube);