import { SEARCH } from "./actionTypes";

const searchErrorReducer = (state = null, action) => {
  switch (action.type) {
    case SEARCH.LOAD_FAIL:
      return action.error;
    case SEARCH.LOAD:
    case SEARCH.LOAD_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default searchErrorReducer;
