import {put, call, takeEvery} from "redux-saga/effects";

import { setSetting,setError } from "./action";
import { SETTING } from "./actionTypes";
import { fetchSetting } from "../../api/index";

export function* handleSettingLoad(action) {
    try {
        const setting = yield call(fetchSetting);
        yield put(setSetting(setting));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchSettingLoad() {
    yield takeEvery(SETTING.LOAD, handleSettingLoad);
}
