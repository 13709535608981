const SIXTH_SECTION_LEFT_NEWS = {
    LOAD: "SIXTH_SECTION_LEFT_NEWS_LOAD",
    LOAD_SUCCESS: "SIXTH_SECTION_LEFT_NEWS_LOAD_SUCCESS",
    LOAD_FAIL: "SIXTH_SECTION_LEFT_NEWS_LOAD_FAIL"
};

const SIXTH_SECTION_RIGHT_NEWS = {
    LOAD: "SIXTH_SECTION_RIGHT_NEWS_LOAD",
    LOAD_SUCCESS: "SIXTH_SECTION_RIGHT_NEWS_LOAD_SUCCESS",
    LOAD_FAIL: "SIXTH_SECTION_RIGHT_NEWS_LOAD_FAIL"
};

export { SIXTH_SECTION_LEFT_NEWS, SIXTH_SECTION_RIGHT_NEWS };