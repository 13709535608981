import { SEARCH } from "./actionTypes";

const loadSearch = (search) => ({
    type: SEARCH.LOAD,
    search
});

const setSearch = searches => ({
    type: SEARCH.LOAD_SUCCESS,
    payload : searches
});

const setError = error => ({
    type: SEARCH.LOAD_FAIL,
    error
});


export {loadSearch, setSearch, setError};
