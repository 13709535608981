import {put, call, takeEvery} from "redux-saga/effects";

import {setFourthSectionNews, setError} from "./action";
import {FOURTH_SECTION_NEWS} from "./actionTypes";
import {fetchNews} from "../../api/index";

export function* handleFourthSectionNewsLoad(action) {
    try {
        const { serialNo } = action.serialNo;
        const news = yield call(fetchNews, serialNo, 7, null);
        yield put(setFourthSectionNews(news));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchFourthSectionNewsLoad() {
    yield takeEvery(FOURTH_SECTION_NEWS.LOAD, handleFourthSectionNewsLoad);
}
