import React,{Fragment} from 'react';
import './EmptyComponent.css';

const EmptyComponent = ({message}) => {
    return(
        <Fragment>
            <div id="main">
                <div className="fof">
                    <h3>{message ? message : <span className="not-found">404 - NOT FOUND</span>}</h3>
                </div>
            </div>
        </Fragment>
    )
};

export default EmptyComponent;