import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectSettingItem} from "../../redux/setting/setting.selector";
import {selectMenuesItems} from '../../redux/menues/menues.selector';
import {withRouter} from "react-router-dom";
import './Footer.css';
import FbPage from '../../components/socialSharing/FbPage';

class Footer extends Component {
    render() {
        const {setting, menues, history, match} = this.props;
        return (
            <Fragment>
                <footer>
                    <div className="container">
                        <div className="row">
                            {menues.filter((menu, index) => index > 0 && index <= 2).map(menu =>
                                <div className="col-md-3" key={menu.id}>
                                    <div className="footer_widget">
                                        <h3>{menu.category_name}</h3>
                                        <ul>
                                            {menu.subcategory.length > 0 ? menu.subcategory.map(item => (
                                                <li className="footer-menu" key={item.id}
                                                    onClick={() => history.push(`${match.url}${item.slug}`)}>
                                                    {item.category_name}
                                                </li>
                                            )) : ''}
                                        </ul>
                                    </div>
                                </div>
                            )}
                            <div className="col-md-3">
                                <div className="footer_widget">
                                    <h3>ঠিকানা</h3>
                                    <p>
                                        {setting.address}
                                    </p>
                                    <p>
                                        ফোন: {setting.phone}
                                    </p>
                                    <p>
                                        ই-মেইল: {setting.email}
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <FbPage height="250"/>
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="container">
                            <p>Copyright © {new Date().getFullYear()} Pratidin Prakasani Pvt. Ltd. All rights
                                reserved</p>
                        </div>
                    </div>
                </footer>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    setting: selectSettingItem,
    menues: selectMenuesItems
});

export default withRouter(connect(mapStateToProps, null)(Footer));