import React from "react";
import "./FormInput.css";

function FormInput({ handleChange, ...otherProps }) {
  return (
    <div className='group'>
      <input className='form-control' onChange={handleChange} {...otherProps} />
    </div>
  );
}

export default FormInput;
