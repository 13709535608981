import { GALLERY } from "./actionTypes";

const loadGallery = (take) => ({
    type: GALLERY.LOAD,
    take
});

const setGallery = galleries => ({
    type: GALLERY.LOAD_SUCCESS,
    payload : galleries
});

const setError = error => ({
    type: GALLERY.LOAD_FAIL,
    error
});


export {loadGallery, setGallery, setError};
