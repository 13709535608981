import React, {Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import './FirstSectionMainFeature.css';
import LongText from '../../../utilities/LongText';

const MainFeature = ({news, history, match}) => {
    return (
        <Fragment>
            <div className="col-md-6">
                <div className="main_banner" onClick={() =>
                    history.push(
                        `${match.url}news/${news.categories[0].slug}/${news.id}/${news.slug}`
                    )
                }>
                    <img src={news.image ? news.image.feature_image : ''} alt={news.title}/>
                    <h3><LongText content={news.title} limit='80'/></h3>
                </div>
            </div>
        </Fragment>
    )
};

export default withRouter(MainFeature);
