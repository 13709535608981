import { YOUTUBE } from "./actionTypes";

const loadYoutube = (take) => ({
    type: YOUTUBE.LOAD,
    take
});

const setYoutube = videos => ({
    type: YOUTUBE.LOAD_SUCCESS,
    payload : videos
});

const setError = error => ({
    type: YOUTUBE.LOAD_FAIL,
    error
});


export {loadYoutube, setYoutube, setError};
