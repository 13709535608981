import {TAGS} from "./actionTypes";

const INITIAL_STATE = {
    tagsNews : []
};

const latestTagsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case TAGS.LOAD_SUCCESS :
            return {
                ...state,
                tagsNews : action.payload
            };
        default :
            return state;
    }
};

export default latestTagsReducer;
