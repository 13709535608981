import {FIFTH_RIGHT_SECTION_NEWS} from "./actionTypes";

const loadFifthRightSectionNews = (serialNo) => ({
    type: FIFTH_RIGHT_SECTION_NEWS.LOAD,
    serialNo
});

const setFifthRightSectionNews = news => ({
    type: FIFTH_RIGHT_SECTION_NEWS.LOAD_SUCCESS,
    payload : news
});

const setError = error => ({
    type: FIFTH_RIGHT_SECTION_NEWS.LOAD_FAIL,
    error
});


export {loadFifthRightSectionNews, setFifthRightSectionNews, setError};
