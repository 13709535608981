import { FIRST_SECTION_NEWS } from "./actionTypes";

const INITIAL_STATE = {
    FirstSection : []
};

const latestNewsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case FIRST_SECTION_NEWS.LOAD_SUCCESS :
            return {
                ...state,
                FirstSection : action.payload
            };
        default :
            return state;
    }
};

export default latestNewsReducer;
