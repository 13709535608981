import { put, call, takeEvery } from "redux-saga/effects";

import { setMenues, setError } from "./action";
import { MENUES } from "./actionTypes";
import { fetchMenues } from "../../api/index";

export function* handleMenuesLoad() {
  try {
    const menues = yield call(fetchMenues);
    yield put(setMenues(menues));
  } catch (error) {
    yield put(setError(error.toString()));
  }
}

export default function* watchMenuesLoad() {
  yield takeEvery(MENUES.LOAD, handleMenuesLoad);
}
