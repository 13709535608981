import {createSelector} from 'reselect';

const selectPopularNews = (state) => state.popularNews;

export const selectPopularNewsItems = createSelector(
    [selectPopularNews],
    popularNews => popularNews.popularNews
);

export const selectPopularFeature = createSelector(
    [selectPopularNews],
    popularNews => popularNews.popularNews.filter((news, index) => index < 2)
);

export const selectPopularList = createSelector(
    [selectPopularNews],
    popularNews => popularNews.popularNews.filter((news, index) => index >= 2)
);