import { POPULAR_NEWS } from "./actionTypes";

const INITIAL_STATE = {
    popularNews: []
};

const popularNewsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case POPULAR_NEWS.LOAD_SUCCESS :
            return {
                ...state,
                popularNews: action.payload
            };
        default :
            return state;
    }
};

export default popularNewsReducer;
