import {JOB_DETAIL} from "./actionTypes";

const INITIAL_STATE = {
    jobDetail : []
};

const latestJobDetailReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case JOB_DETAIL.LOAD_SUCCESS :
            return {
                ...state,
                jobDetail : action.payload
            };
        default :
            return state;
    }
};

export default latestJobDetailReducer;
