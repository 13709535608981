import React, {Component, Fragment} from 'react';
import SignInSignUp from '../signInSignUp/SignInSignUp';
import {connect} from 'react-redux';
import {Button, Spinner} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import {selectUserItem} from '../../redux/user/user.selector';
import {createStructuredSelector} from 'reselect';
import axios from 'axios';
import ToastMessage from '../../components/notify/Toast';
import {BASE_URL} from '../../constants/index';
import './StoreComment.css';

class StoreComment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comment : "",
            isLoading: false,
            error: false,
            message: ""
        };
    }

    handleChange = event => {
        const {value, name} = event.target;
        this.setState({[name]: value});
    };

    handleSubmit = event => {
        event.preventDefault();
        this.setState(prevState => ({
            isLoading: !prevState.isLoading
        }));

        const { comment } = this.state;
        const {currentUser} = this.props;
        const {newsId} = this.props.match.params;
        try {
            const data = {
                comment,
                user_id : currentUser.id,
                news_id : newsId
            };
            axios.post(`${BASE_URL}/comment`, data)
                .then(res => {
                    this.setState(prevState => ({
                        isLoading: !prevState.isLoading,
                        message: 'Thank You For Your Request.Show Comment After Review'
                    }));
                })
                .catch(error => {
                    this.setState(prevState => ({
                        isLoading: !prevState.isLoading,
                        error: !prevState.error,
                        message: error.response.data.message
                    }));
                });
            this.setState({comment: ""});
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        const {currentUser} = this.props;
        const {isLoading, message, error} = this.state;

        return (
            <Fragment>
                <div className="obiroto_comments">
                    <h3>মন্তব্য করুণ</h3>
                    <div className="obiroto_post">
                        {currentUser ? (
                            <form onSubmit={this.handleSubmit}>
                                <textarea className="form-control" name="comment" id="comment" rows="3" cols='50' required placeholder="Write Something" onChange={this.handleChange} value={this.state.comment}/>
                                {isLoading ? ( <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Process...
                                </Button>) : (
                                    <Button type="submit" variant="primary">
                                        Submit
                                    </Button>
                                )}
                            </form>
                        ) : (<SignInSignUp />)}
                    </div>
                </div>
                {message ? <ToastMessage isShow message={message} type={error ? 2 : 1}/> : ''}
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectUserItem
});


export default withRouter(connect(mapStateToProps, null)(StoreComment));
