import React, {Fragment} from 'react';
import LeftSection from '../sixthSection/LeftSection/LeftSection';
import RightSection from '../sixthSection/rightSection/RightSection';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import Image from '../Image/Image';
import {selectAdsItems} from '../../redux/Ads/ads.selector';

const Technology = ({ads}) => {
    return (
        <Fragment>
            <div className="new_technology_section">
                <div className="row">
                    <LeftSection/>
                    <RightSection/>
                </div>
                <div className="tec_ad">
                    {ads.filter((ad, index) => index === 5).map(ad =>
                        <Image ad={ad} key={ad.id}/>
                    )}
                </div>
            </div>
        </Fragment>
    )
};

const mapStateToProps = createStructuredSelector({
    ads: selectAdsItems
});

export default connect(mapStateToProps, null)(Technology);