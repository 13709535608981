import { CATEGORY_NEWS } from "./actionTypes";

const loadCategoryNews = (category) => ({
    type: CATEGORY_NEWS.LOAD,
    category
});

const setCategoryNews = categoryNews => ({
    type: CATEGORY_NEWS.LOAD_SUCCESS,
    payload : categoryNews
});

const setError = error => ({
    type: CATEGORY_NEWS.LOAD_FAIL,
    error
});


export {loadCategoryNews, setCategoryNews, setError};
