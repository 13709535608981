import { MENUES } from "./actionTypes";

const loadMenues = () => ({
  type: MENUES.LOAD
});

const setMenues = menues => ({
  type: MENUES.LOAD_SUCCESS,
  payload : menues
});

const setError = error => ({
  type: MENUES.LOAD_FAIL,
  error
});

export { loadMenues, setMenues, setError };
