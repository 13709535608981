import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {loadThirdSectiontNews} from '../../redux/thirdSection/action';
import PopularFeature from '../popularFeature/PopularFeature';
import PopularList from '../popularList/PopularList';
import {createStructuredSelector} from 'reselect';
import {selectThirdSectionFeature, selectThirdSectionList} from '../../redux/thirdSection/thirdSection.selector';
//import Image from '../Image/Image';
//import {selectAdsItems} from '../../redux/Ads/ads.selector';
import {withRouter} from 'react-router-dom';
import FbPage from '../socialSharing/FbPage';

class PopularNews extends Component {
    componentDidMount() {
        this.props.loadThirdSectiontNews({serialNo: 4});
    }

    render() {
        const {thirdSectionFeature, thirdSectionList, history, match} = this.props;
        return (
            <Fragment>
                {thirdSectionFeature.length > 0 ? (
                    <section className="bg_gray">
                        <div className="container">
                            <div className="popular_section">
                                <div className="content_title">
                                    <h3 className="category_title"
                                        onClick={() => history.push(`${match.url}category/${thirdSectionFeature[0].categories[0].slug}`)}>
                                        {thirdSectionFeature[0].categories[0].title}
                                    </h3>
                                </div>
                                <div className="row">
                                    {thirdSectionFeature.map(news => <PopularFeature key={news.id} news={news}
                                                                                     textLimit="40"/>)}

                                    <div className="col-md-3">
                                        {thirdSectionList ? <PopularList popularNewsList={thirdSectionList}/> : '' }
                                    </div>

                                    <div className="col-md-3">
                                        <div className="popular_ad">
                                            <FbPage/>
                                            {/* {ads.filter((ad, index) => index === 3).map(ad =>
                                             <Image ad={ad} key={ad.id}/>
                                             )}*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : ''}
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    thirdSectionFeature: selectThirdSectionFeature,
    thirdSectionList: selectThirdSectionList,
    //ads: selectAdsItems
});

const mapDispatchToProps = dispatch => ({
    loadThirdSectiontNews: (take) => dispatch(loadThirdSectiontNews(take))
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PopularNews));