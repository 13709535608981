import {MENUES} from "./actionTypes";

const INITIAL_STATE = {
    menues: []
};

const menuesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MENUES.LOAD_SUCCESS :
            return {
                ...state,
                menues: action.payload
            };
        default :
            return state;
    }
};

export default menuesReducer;
