import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import SingleList from '../../components/newsList/SingleList';
import {createStructuredSelector} from 'reselect';
import {loadRelatedNews} from '../../redux/relatedNews/action';
import {selectRelatedNewsList} from '../../redux/relatedNews/relatedNews.selector';

class RelatedNews extends Component {
    componentDidMount() {
        const {match} = this.props;
        const {category,newsId} = match.params;
        this.props.loadRelatedNews({category: category, except: newsId, take: 4});
    }

    componentDidUpdate(prevProps) {
        const {dispatch, match} = this.props;
        const {category,newsId} = match.params;
        if (newsId !== prevProps.match.params.newsId) {
            dispatch(loadRelatedNews({category: category, except: newsId, take: 4}));
        }
    }

    render() {
        const {relatedNews} = this.props;
        return (
            <Fragment>
                <div className="related_post">
                    <div className="interview_section">
                        <div className="content_title">
                            <h3>
                                রিলেটেড আর্টিকেল
                            </h3>
                        </div>
                        {relatedNews.map(news => (
                            <SingleList key={news.id} news={news}/>
                        ))}
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    relatedNews : selectRelatedNewsList
});

const mapDispatchToProps = dispatch => ({
    loadRelatedNews: (category, except, take) => dispatch(loadRelatedNews(category, except, take)),
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RelatedNews));