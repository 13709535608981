import { LATEST_NEWS } from "./actionTypes";

const latestNewsErrorReducer = (state = null, action) => {
  switch (action.type) {
    case LATEST_NEWS.LOAD_FAIL:
      return action.error;
    case LATEST_NEWS.LOAD:
    case LATEST_NEWS.LOAD_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default latestNewsErrorReducer;
