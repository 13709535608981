import {createSelector} from 'reselect';

const selectNews = (state) => state.FirstSection;

export const selectFirstSectionItems = createSelector(
    [selectNews],
    FirstSection => FirstSection.FirstSection
);

export const selectFirstSectionFeatureItems = createSelector(
    [selectFirstSectionItems],
    FirstSection => FirstSection.filter((news, index) => index < 3)
);

export const selectFirstSectionListItems = createSelector(
    [selectFirstSectionItems],
    FirstSection => FirstSection.filter((news, index) => index > 2)
);