import React,{Fragment, Component} from 'react';
import PopularNews from '../../components/PopularNewsSidebar/PopularNewsSidebar';
import SidebarInterview from '../sideInterview/SideInterview';
import {connect} from "react-redux";
import {selectAdsItems} from '../../redux/Ads/ads.selector';
import Image from '../Image/Image';
import {createStructuredSelector} from 'reselect';
import FbPage from '../socialSharing/FbPage';

class Sidebar extends Component {
    render() {
        const {ads} = this.props;
        return(
            <Fragment>
                <div className="col-md-3">
                    <div className="ad_banner_top">
                        {ads.filter((ad, index) => index === 7).map(ad =>
                            <Image ad={ad} key={ad.id}/>
                        )}
                    </div>
                    <PopularNews />
                    <SidebarInterview />
                    <FbPage/>
                </div>
            </Fragment>
        )
    }
}


const mapStateToProps = createStructuredSelector({
    ads : selectAdsItems
});

export default connect(
    mapStateToProps,
    null
)(Sidebar);