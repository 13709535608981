import {put, call, takeEvery} from "redux-saga/effects";

import { setGallery,setError } from "./action";
import { GALLERY } from "./actionTypes";
import { fetchGalleries } from "../../api";

export function* handleGalleriesLoad(action) {
    try {
        const { take } = action.take;
        const images = yield call(fetchGalleries, take);
        yield put(setGallery(images));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}



export default function* watchGalleriesLoad() {
    yield takeEvery(GALLERY.LOAD, handleGalleriesLoad);
}
