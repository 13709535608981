import {USER} from "./actionTypes";

const INITIAL_STATE = {
    user: []
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER.LOAD_SUCCESS :
            return {
                ...state,
                user: action.payload
            };
        case USER.LOG_OUT :
            return {
                ...state,
                user: action.payload
            };
        default :
            return state;
    }
};

export default userReducer;
