import {combineReducers} from "redux";

import loadingReducer from "./menues/loadingReducer";
import menuesReducer from "./menues/menuesReducer";
import errorReducer from "./menues/errorReducer";
import FirstSectionReducer from "./FirstSection/FirstSectionReducer";
import FirstSectionErrorReducer from "./FirstSection/FirstSectionErrorReducer";

//Latest News
import LatestNewsReducer from "./latestNews/latestNewsReducer";
import LatestNewsReducerError from "./latestNews/latestNewsErrorReducer";

//Load News
import newsReducer from './news/newsReducer';
import newsErrorReducer from './news/newsErrorReducer';

//latest Feature
import latestFeatureNews from "./latestFeature/latestFeatureReducer";
import latestFeatureErrorNews from "./latestFeature/latestFeatureErrorReducer";

//Latest List News
import LatestListNews from './latestList/latestListReducer';
import LatestListErrorNews from './latestList/latestListErrorReducer';

//News Detail
import NewsDetail from './newsDetails/newsDetailReducer';
import NewsDetailErrorReducer from './newsDetails/newsDetailErrorReducer';

//News Detail Slug wise
import NewsDetailSlug from './newsDetailsSlug/newsDetailSlugReducer';
import NewsDetailSlugErrorReducer from './newsDetailsSlug/newsDetailSlugErrorReducer';

//Interviews
import Interviews from './Interview/InterviewsReducer';
import InterviewsErrors from  './Interview/InterviewsErrorReducer';

//Interview Details
import InterviewDetailReducer from './interviewDetails/interviewDetailReducer';
import InterviewDetailErrorReducer from './interviewDetails/interviewDetailErrorReducer';

//Category News
import CategoryNews from './categoryNews/categoryNewsReducer';
import CategoryNewsErrors from './categoryNews/categoryNewsErrorReducer';

//Category News
import PopularNews from './popularNews/popularNewsReducer';
import PopularNewsErrors from './popularNews/popularNewsErrorReducer';

//User
import UserReducer from './user/userReducer';
import UserErrors from './user/userErrorReducer';

//Ads
import AdsReducer from './Ads/adsReducer';
import AdsErrors from './Ads/adsErrorReducer';

//Archives
import ArchiveReducer from './archive/archiveReducer';
import ArchiveErrorReducer from './archive/archiveErrorReducer';

//Jobs
import JobReducer from './latestJob/latestJobReducer';
import JobErrorReducer from './latestJob/latestJobErrorReducer';

//Jobs Detail
import JobDetailReducer from './jobDetails/jobDetailReducer';
import JobDetailErrorReducer from './jobDetails/jobDetailErrorReducer';

//Setting
import SettingReducer from './setting/settingReducer';
import SettingErrorReducer from './setting/settingErrorReducer';

//Search
import SearchReducer from './search/searchReducer';
import SearchErrorReducer from './search/searchErrorReducer';

//second section News
/*Left*/
import secondSectionLeftReducer from './secondSection/left/secondSectionLeftReducer';
import secondSectionLeftErrorReducer from './secondSection/left/secondSectionLeftErrorReducer';

/*Left*/
import secondSectionRightReducer from './secondSection/right/secondSectionRightReducer';
import secondSectionRightErrorReducer from './secondSection/right/secondSectionRightErrorReducer';

//third section News
import thirdSectionNews from './thirdSection/thirdSectionReducer';
import thirdSectionErrorReducer from './thirdSection/thirdSectionNewsErrorReducer';

//Fourth section News
import fourthSectionNews from './fourthSection/fourthSectionReducer';
import fourthSectionErrorReducer from './fourthSection/fourthSectionErrorReducer';

//Fifth section News
//Left
import fifthLeftSectionNews from './FifthSection/Left/fifthLeftSectionReducer';
import fifthLeftSectionErrorReducer from './FifthSection/Left/fifthLeftSectionErrorReducer';
//Middle
import fifthMiddleSectionNews from './FifthSection/Middle/fifthMiddleSectionReducer';
import fifthMiddleSectionErrorReducer from './FifthSection/Middle/fifthMiddleSectionErrorReducer';
//Right
import fifthRightSectionNews from './FifthSection/Right/fifthRightSectionReducer';
import fifthRightSectionErrorReducer from './FifthSection/Right/fifthRightSectionErrorReducer';

//Sixth Section
import sixthSection from './sixthSection/sixthSectionReducer';
import sixthSectionError from './sixthSection/sixthSectionNewsErrorReducer';

//Media
//Galleries
import Galleries from './Gallery/galleryReducer';
import GalleriesError from './Gallery/galleryErrorReducer';

//Related News
import relatedNews from './relatedNews/relatedNewsReducer';
import relatedNewsErrorReducer from './relatedNews/relatedNewsErrorReducer';

//Subscription Verify
import subscriptionVerify from './subscriptionVerification/subscriptionVerifyReducer';
import subscriptionVerifyError from  './subscriptionVerification/subscriptionVerifyErrorReducer';

//User Verify
import userVerifyStatus from './userVerification/userVerifyReducer';
import userVerifyStatusError from  './userVerification/userVerifyErrorReducer';

//Youtube Videos
import YoutubeVideos from './Youtube/youtubeReducer';
import YoutubeVideosErrors from './Youtube/youtubeErrorReducer';

//Tags
import TagReducer from './Tags/tagsReducer';
import TagErrorReducer from './Tags/tagsErrorReducer';


const rootReducer = combineReducers({

    isLoading: loadingReducer,
    menues: menuesReducer,
    error: errorReducer,

    news : newsReducer,
    newsError : newsErrorReducer,

    FirstSection: FirstSectionReducer,
    FirstSectionError: FirstSectionErrorReducer,

    latestFeatureNews: latestFeatureNews,
    latestFeatureErrorNews: latestFeatureErrorNews,
    latestListNews: LatestListNews,
    latestListErrorNews: LatestListErrorNews,

    newsDetail : NewsDetail,
    newsDetailError : NewsDetailErrorReducer,

    newsDetailSlug : NewsDetailSlug,
    newsDetailSlugError : NewsDetailSlugErrorReducer,

    interviews : Interviews,
    interviewsErrors : InterviewsErrors,

    interviewDetail : InterviewDetailReducer,
    interviewDetailError : InterviewDetailErrorReducer,

    categoryNews : CategoryNews,
    categoryNewsErrors : CategoryNewsErrors,

    popularNews : PopularNews,
    popularNewsErrors : PopularNewsErrors,

    user : UserReducer,
    userError : UserErrors ,

    ads : AdsReducer,
    adsError : AdsErrors,

    archives : ArchiveReducer,
    archivesError : ArchiveErrorReducer,

    jobs : JobReducer,
    jobsError : JobErrorReducer,

    jobDetail : JobDetailReducer,
    jobDetailsError : JobDetailErrorReducer,

    setting : SettingReducer,
    settingError : SettingErrorReducer,

    searches : SearchReducer,
    searchError : SearchErrorReducer,

    secondSectionLeft : secondSectionLeftReducer,
    secondSectionLeftError : secondSectionLeftErrorReducer,
    secondSectionRight : secondSectionRightReducer,
    secondSectionRightError : secondSectionRightErrorReducer,

    thirdSectionNews : thirdSectionNews,
    thirdSectionError : thirdSectionErrorReducer,

    fourthSectionNews : fourthSectionNews,
    fourthSectionError : fourthSectionErrorReducer,

    fifthLeftSectionNews : fifthLeftSectionNews,
    fifthLeftSectionErrorNews : fifthLeftSectionErrorReducer,
    fifthMiddleSectionNews : fifthMiddleSectionNews,
    fifthMiddleSectionErrorNews : fifthMiddleSectionErrorReducer,
    fifthRightSectionNews : fifthRightSectionNews,
    fifthRightSectionErrorNews : fifthRightSectionErrorReducer,

    sixthSectionLeftNews : sixthSection,
    sixthSectionRightNews : sixthSection,
    sixthSectionRightNewsError : sixthSectionError,

    relatedNews : relatedNews,
    relatedNewsError : relatedNewsErrorReducer,

    subscriptionStatus : subscriptionVerify,
    subscriptionVerifyError : subscriptionVerifyError,

    userVerifyStatus : userVerifyStatus,
    userVerifyError : userVerifyStatusError,

    galleries : Galleries,
    galleriesErrro : GalleriesError,

    youtubeVideos : YoutubeVideos,
    youtubeVideosErrors : YoutubeVideosErrors,

    latestNews : LatestNewsReducer,
    latestNewsError : LatestNewsReducerError,

    tagsNews : TagReducer,
    tagsError : TagErrorReducer
});

export default rootReducer;
