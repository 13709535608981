import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {loadNewsDetail} from '../../redux/newsDetails/action';
import NewsDetailContent from '../../components/NewsDetailContent/NewsDetailContent';
import Sidebar from '../../components/sidebar/Sidebar';
import RelatedNews from '../../components/relatedNews/RelatedNews';
import Comment from '../../components/comment/Comment';
import StoreComment from '../../components/comment/StoreComment';
//import ShareThis from '../../components/socialSharing/ShareThis';
import Sharing from '../../components/socialSharing/Sharing';
import {withRouter} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';
import {selectNewsDetailItem} from '../../redux/newsDetails/newsDetails.selector';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import './NewsDetail.css';
//import EmptyContent from '../../components/EmptyComponent/EmptyComponent';
import Tag from '../../components/Tags/Tags';

class NewsDetail extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        const {match} = this.props;
        const {newsId} = match.params;
        this.props.loadNewsDetail({newsId: newsId});
    }

    componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);
        const {dispatch} = this.props;
        if (this.props.match.params.newsId !== prevProps.match.params.newsId) {
            dispatch(loadNewsDetail({newsId: this.props.match.params.newsId}));
        }
    }

    render() {
        if (typeof window !== 'undefined') {
            var newsPath = window.location.protocol + '//' + window.location.host + window.location.pathname;
        }
        const {newsDetail} = this.props;
        const comments = newsDetail ? newsDetail.comments : [];
        return (
            <Fragment>
                <section className="bg_white">
                    <div className="banner_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="inner_content">

                                        {newsDetail ? (
                                            <ErrorBoundary>
                                                <NewsDetailContent newsDetail={newsDetail}/>
                                                {newsDetail.tags.length > 0 ? newsDetail.tags.map((tag) => <Tag key={tag.id} tag={tag}/>) : ''}
                                                <Sharing shareUrl={newsPath}/>
                                             {/*   <ShareThis
                                                    url={newsPath}
                                                    title={newsDetail.title}
                                                    shortDescription={newsDetail.short_description}
                                                    image={newsDetail.image.feature_image}
                                                />*/}
                                                <StoreComment/>
                                                {comments.length > 0 ? comments.map((comment) => <Comment key={comment.id}
                                                                                    comment={comment}/>) : ''}
                                            </ErrorBoundary>
                                        ) : ''}
                                    </div>

                                    <RelatedNews />

                                </div>


                                <Sidebar />
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    newsDetail: selectNewsDetailItem
});

const mapDispatchToProps = dispatch => ({
    loadNewsDetail: (newsId) => dispatch(loadNewsDetail(newsId)),
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(NewsDetail));