import React, {Component, Fragment} from 'react';
import './Job.css';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {loadLatestJob} from '../../redux/latestJob/action';
import {selectJobItems} from '../../redux/latestJob/latestJob.selector';
import {Media} from 'react-bootstrap';
import LongText from '../../utilities/LongText';
import {withRouter} from 'react-router-dom';

class Jobs extends Component {
    componentDidMount() {
        this.props.loadLatestJob({take: 30});
    }
    render() {
        const {jobs,history} = this.props;
        return (
            <Fragment>
                <div className="container">
                    <div className="search-content">
                        <ul className="list-unstyled">
                            {jobs ? jobs.map(job => (
                                <Media key={job.id} className="list-item" as="li" onClick={() =>
                                    history.push(
                                        `/jobs/${job.id}/${job.slug}`
                                    )
                                }>
                                    <img
                                        width={64}
                                        height={64}
                                        className="mr-3"
                                        src={job.logo ? job.logo : ''}
                                        alt={job.title}
                                    />
                                    <Media.Body>
                                        <h5>{job.title}</h5>
                                        <p>
                                            <LongText content={job.designation} limit='250'/>
                                        </p>
                                    </Media.Body>
                                </Media>
                            )) : ''}

                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    jobs : selectJobItems
});

const mapDispatchToProps = dispatch => ({
    loadLatestJob: (take) => dispatch(loadLatestJob(take))
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Jobs));
