import React, {Fragment, Component} from 'react';
import './Tags.css';
import {withRouter} from 'react-router-dom';

class Tags extends Component {
    render() {
        const {tag, history} = this.props;
        return(
            <Fragment>
                <div className="tags" onClick={() =>
                history.push(
                    `/tags/${tag.slug}/news`
                )}
                >
                    <span className="badge badge-light">{tag.title}</span>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(Tags);