import { ADS } from "./actionTypes";

const adsErrorReducer = (state = null, action) => {
  switch (action.type) {
    case ADS.LOAD_FAIL:
      return action.error;
    case ADS.LOAD:
    case ADS.LOAD_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default adsErrorReducer;
