import { THIRD_SECTION_NEWS } from "./actionTypes";

const INITIAL_STATE = {
    thirdSectionNews : []
};

const thirdSectionNewsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case THIRD_SECTION_NEWS.LOAD_SUCCESS :
            return {
                ...state,
                thirdSectionNews : action.payload
            };
        default :
            return state;
    }
};

export default thirdSectionNewsReducer;
