import React, {Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import LongText from '../../utilities/LongText';
import './PopularFeature.css';

const PopularFeature = ({news, history, match, textLimit}) => {
    return(
        <Fragment>
            <div className="col-md-3">
                <div className="popular_single" onClick={() =>
                    history.push(
                        `${match.url}news/${news.categories[0].slug}/${news.id}/${news.slug}`
                    )
                }>
                    <img src={news.image ? news.image.highlighted_image : ''} alt={news.title}/>
                    <div className="popular_single_content">
                        <h5>{news.title}</h5>
                        <span>{news.published_time}</span>
                        <p>
                            <LongText content={news.short_description} limit={textLimit}/>
                        </p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default withRouter(PopularFeature);