import React, {Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import LongText from '../../../utilities/LongText';

const CardList = ({news,history, match}) => {
    return (
        <Fragment>
            <li onClick={() =>
                history.push(
                    `${match.url}news/${news.categories[0].slug}/${news.id}/${news.slug}`
                )
            }>
                <LongText content={news.title} limit='45'/>
            </li>
        </Fragment>
    )
};

export default withRouter(CardList);