import { ARCHIVE } from "./actionTypes";

const ArchiveErrorReducer = (state = null, action) => {
  switch (action.type) {
    case ARCHIVE.LOAD_FAIL:
      return action.error;
    case ARCHIVE.LOAD:
    case ARCHIVE.LOAD_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default ArchiveErrorReducer;
