import { POPULAR_NEWS } from "./actionTypes";

const popularNewsErrorReducer = (state = null, action) => {
  switch (action.type) {
    case POPULAR_NEWS.LOAD_FAIL:
      return action.error;
    case POPULAR_NEWS.LOAD:
    case POPULAR_NEWS.LOAD_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default popularNewsErrorReducer;
