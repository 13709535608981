import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {loadTagNews} from "../../redux/Tags/action";
import CategoryFeature from "../../components/categoryFeature/CategoryFeature";
import SingleList from "../../components/newsList/SingleList";
import Sidebar from "../../components/sidebar/Sidebar";
import {createStructuredSelector} from "reselect";
import {selectTagNewsList} from "../../redux/Tags/tags.selector";
import Pagination from "react-js-pagination";

class Tags extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getNews();
    }

    componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);
        if (this.props.match.params.slug !== prevProps.match.params.slug) {
            this.getNews();
        }
    }

    getNews(pageNumber = 1) {
        const {match} = this.props;
        const {slug} = match.params;
        this.props.loadTagNews({slug: slug, pageNumber: pageNumber});
    }

    render() {
        const {data, current_page, per_page, total} = this.props.tagNews;
        return (
            <Fragment>
                <section className='bg_white'>
                    <div className='banner_section'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-9'>
                                    {data && data[0] ? <Fragment>
                                        <div className='inner_content'>
                                            <CategoryFeature news={data[0]}/>
                                        </div>
                                        <div className='related_post'>
                                            <div className='interview_section'>
                                                {data
                                                    .filter((news, index) => index > 0)
                                                    .map((news) => (
                                                        <SingleList key={news.id} news={news}/>
                                                    ))}
                                            </div>
                                            <Pagination
                                                activePage={current_page}
                                                itemsCountPerPage={per_page}
                                                totalItemsCount={total}
                                                pageRangeDisplayed={5}
                                                onChange={(pageNumber) => this.getNews(pageNumber)}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                firstPageText="First"
                                                lastPageText="Last"
                                            />
                                        </div>
                                    </Fragment> :
                                        ''
                                    }
                                </div>

                                <Sidebar />
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    tagNews: selectTagNewsList,
});

const mapDispatchToProps = (dispatch) => ({
    loadTagNews: (tag) => dispatch(loadTagNews(tag)),
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
