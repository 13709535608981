import {createSelector} from 'reselect';

const selectNews = (state) => state.news;

export const selectNewsItems = createSelector(
    [selectNews],
    news => news.news
);

export const selectNewsCollection = createSelector(
    [selectNewsItems],
    news => Object.keys(news).map(key => news[key])
);

export const selectCollection = (collectionIdParmes) => createSelector(
    [selectNewsItems],
    news => news[collectionIdParmes]
);

export const selectCollectionDependOnSerial = (serialNo) => createSelector(
    [selectNewsCollection],
    news => news.find(item => item.serial_no === serialNo)
);

//Section Wise News Process
/*----------------------------------------Home First Section Start------------------------------------------------*/
export const sectionWiseDataFilter =(serialNo, start, end) => createSelector(
    [selectNewsCollection],
    news => news.find(item => item.serial_no === serialNo) ? news.find(item => item.serial_no === serialNo).news.filter((news, index) => {
        return index >= start && index <= end;
    }) : undefined
);

/*
export const sectionWiseDataFilter =(serialNo, start, end) => createSelector(
    [selectCollectionDependOnSerial(serialNo)],
    news => news ? news.news.filter((news, index) => {
        return index >= start && index <= end;
    }) : undefined
);
*/




/*----------------------------------------Home First Section End------------------------------------------------*/


