import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {store, persistor, sagaMiddleware} from './redux/store';
import SimpleReactLightbox from "simple-react-lightbox";
import {PersistGate} from 'redux-persist/integration/react';
import rootSaga from './redux/rootSaga';
import ReactGA from 'react-ga';
// import HttpsRedirect from 'react-https-redirect';

sagaMiddleware.run(rootSaga);

ReactGA.initialize('UA-127178140-3');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
                <BrowserRouter basename="/">
                    <PersistGate persistor={persistor}>
                        <SimpleReactLightbox>
                            <App />
                        </SimpleReactLightbox>
                    </PersistGate>
                </BrowserRouter>
        </Provider>
    </React.StrictMode>
    ,
    document.getElementById("root")
);
