import {put, call, takeEvery} from "redux-saga/effects";

import {setFifthLeftSectionNews, setError} from "./action";
import {FIFTH_LEFT_SECTION_NEWS} from "./actionTypes";
import {fetchNews} from "../../../api/index";

export function* handleFifthSectionNewsLoad(action) {
    try {
        const { serialNo } = action.serialNo;
        const news = yield call(fetchNews, serialNo, 4, null);
        yield put(setFifthLeftSectionNews(news));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}


export default function* watchFifthSectionNewsLoad() {
    yield takeEvery(FIFTH_LEFT_SECTION_NEWS.LOAD, handleFifthSectionNewsLoad);
}
