import React, {Component, Fragment} from 'react';
import {Modal, Button, Spinner} from 'react-bootstrap';
import {signInWithGoogle} from '../../firebase/firebase.utils';
import FormInput from "../form-input/FormInput";
import axios from 'axios';
import {BASE_URL} from '../../constants/index';
import {withRouter} from "react-router-dom";
import ToastMessage from '../../components/notify/Toast';

class SignUpModal extends Component {
    constructor() {
        super();
        this.state = {
            show: false,
            displayName: '',
            email: '',
            password: '',
            isLoading: false,
            error: false,
            message: "",
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            isLoading: !prevState.isLoading
        }));

        const {displayName, email, password} = this.state;
        const {history} = this.props;

        try {
            /*const {user} = await auth.createUserWithEmailAndPassword( email, password );
             await createUserProfileDocument(user, {displayName});*/
            const data = {
                name: displayName,
                email: email,
                password: password
            };

            axios.post(`${BASE_URL}/register`, data)
                .then(res => {
                    this.setState(prevState => ({
                        isLoading: !prevState.isLoading,
                    }));
                    history.push('/success');
                })
                .catch(error => {
                    this.setState(prevState => ({
                        isLoading: !prevState.isLoading,
                        error: !prevState.error,
                        message: error.response.data.message
                    }));
                });


            this.setState({
                displayName: '',
                email: '',
                password: ''
            })

        } catch (e) {
            console.error(e);
        }

    };

    handleChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value})
    };

    handleClose = () => this.setState({show: false});
    handleShow = () => this.setState({show: true});

    render() {
        const {displayName, email, password} = this.state;
        const {isLoading, message, error, show} = this.state;
        return (
            <Fragment>
                <Button variant="light" onClick={this.handleShow}>
                    Register
                </Button>

                <Modal show={show} onHide={this.handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Register</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.handleSubmit}>
                        <Modal.Body>
                            <div className="social_login">
                                <Button onClick={signInWithGoogle} className="btn btn-danger btn-block text-white">
                                    <i className="fa fa-google-plus"/>
                                    SIGN UP WITH GOOGLE
                                </Button>
                            </div>
                            <FormInput name='displayName' type='text' placeholder="Name" value={displayName}
                                       required handleChange={this.handleChange}/>
                            <FormInput name='email' type='email' placeholder="Email Address" value={email}
                                       required handleChange={this.handleChange}/>
                            <FormInput name='password' type='password' placeholder="Password" value={password}
                                       required handleChange={this.handleChange}/>
                        </Modal.Body>
                        <Modal.Footer>
                            {isLoading ? ( <Button variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Process...
                            </Button>) : (
                                <Fragment>
                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                    <Button type="submit" variant="primary">
                                        Submit
                                    </Button>
                                </Fragment>
                            )}
                        </Modal.Footer>
                    </form>
                </Modal>
                {message ? <ToastMessage isShow message={message} type={error ? 2 : 1}/> : ''}
            </Fragment>
        )
    }

}

export default withRouter(SignUpModal);