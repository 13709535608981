import { FIFTH_LEFT_SECTION_NEWS } from "./actionTypes";

const INITIAL_STATE = {
    fifthLeftSectionNews : []
};

const fifthSectionNewsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case FIFTH_LEFT_SECTION_NEWS.LOAD_SUCCESS :
            return {
                ...state,
                fifthLeftSectionNews : action.payload
            };
        default :
            return state;
    }
};

export default fifthSectionNewsReducer;
