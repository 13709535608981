import {put, call, takeEvery} from "redux-saga/effects";

import {setLatestListNews, setLatestListError} from "./action";
import {LATEST_LIST_NEWS} from "./actionTypes";
import {fetchNews} from "../../api/index";

export function* handleLatestListNewsLoad() {
    try {
        const latestListNews = yield call(fetchNews, 'সর্বশেষ', 3, 3);
        yield put(setLatestListNews(latestListNews));
    } catch (error) {
        yield put(setLatestListError(error.toString()));
    }
}


export default function* watchLatestNewsLoad() {
    yield takeEvery(LATEST_LIST_NEWS.LOAD, handleLatestListNewsLoad);
}
