import { FIFTH_RIGHT_SECTION_NEWS } from "./actionTypes";

const INITIAL_STATE = {
    fifthRightSectionNews : []
};

const fifthRightSectionNewsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case FIFTH_RIGHT_SECTION_NEWS.LOAD_SUCCESS :
            return {
                ...state,
                fifthRightSectionNews : action.payload
            };
        default :
            return state;
    }
};

export default fifthRightSectionNewsReducer;
