import React, {Fragment, Component} from "react";
import {connect} from "react-redux";
import {loadMenues} from "../../redux/menues/action";
import {withRouter} from "react-router-dom";
import "./Menu.css";
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import {createStructuredSelector} from 'reselect';
import {selectMenuesItems} from '../../redux/menues/menues.selector';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false }
    }

    componentDidMount() {
        this.props.loadMenues();
    }
    render() {
        const {menues, history, match} = this.props;
        return (
            <Fragment>
                <Navbar collapseOnSelect bg="white" expand="lg">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="#" onClick={() => history.push(`/`)}>প্রথম পাতা</Nav.Link>
                            {menues.map(menu =>
                                <Fragment key={menu.id}>
                                    {menu.subcategory.length > 0 ? (
                                        <NavDropdown title={menu.category_name}
                                                     id="collasible-nav-dropdown">
                                                <NavDropdown.Item href="#" className="inner-item"
                                                    onClick={() => history.push(`${match.url}category/${menu.slug}`)}>
                                                    {menu.category_name}
                                                </NavDropdown.Item>
                                                {menu.subcategory.map(item =>
                                                    <NavDropdown.Item href="#" key={item.id} className="inner-item"
                                                        onClick={() => history.push(`${match.url}category/${item.slug}`)}>
                                                        {item.category_name}
                                                    </NavDropdown.Item>
                                                )}
                                        </NavDropdown>
                                    ) : <Nav.Link href="#"
                                        onClick={() => history.push(`${match.url}category/${menu.slug}`)}>
                                        {menu.category_name}
                                    </Nav.Link> }
                                </Fragment>
                            )}
                            <Nav.Link href="#" onClick={() => history.push(`/latest/jobs`)}>চাকরী</Nav.Link>
                            <Nav.Link href="#" onClick={() => history.push(`/photo/gallery`)}>ফটো</Nav.Link>
                            <Nav.Link href="#" onClick={() => history.push(`/video/gallery`)}>ভিডিও</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    menues: selectMenuesItems
});

const mapDispatchToProps = dispatch => ({
    loadMenues: () => dispatch(loadMenues())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
