import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {loadGallery} from '../../../redux/Gallery/action';
import {selectGalleriesItems} from '../../../redux/Gallery/gallery.selectior';
import PhotoWithCaption from '../../commonComponent/PhotoWithCaption/PhotoWithCaption';
import {SRLWrapper} from "simple-react-lightbox";
import {withRouter} from 'react-router-dom';

class Gallery extends Component {
    componentDidMount() {
        this.props.loadGallery({take: 4});
    }

    render() {
        const {galleries,history} = this.props;
        return (
            <Fragment>
                <div className="col-md-6">
                    <div className="content_title">
                        <h3 className="category_title" onClick={() => history.push(`/photo/gallery`)}>
                            ফটো
                        </h3>

                    </div>
                    <SRLWrapper>
                        <div className="row">
                            {galleries ? galleries.map(photo => (
                                <div className="col-md-6" key={photo.id}>
                                    <PhotoWithCaption item={photo}/>
                                </div>
                            )) : ''}
                        </div>
                    </SRLWrapper>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    galleries: selectGalleriesItems,
});

const mapDispatchToProps = dispatch => ({
    loadGallery: (take) => dispatch(loadGallery(take))
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Gallery));