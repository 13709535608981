import { SUBSCRIPTION_VERIFY } from "./actionTypes";

const subscriptionCheck = (token) => ({
    type: SUBSCRIPTION_VERIFY.LOAD,
    token
});

const setVerifyStatus = status => ({
    type: SUBSCRIPTION_VERIFY.LOAD_SUCCESS,
    payload : status
});

const setError = error => ({
    type: SUBSCRIPTION_VERIFY.LOAD_FAIL,
    error
});


export {subscriptionCheck, setVerifyStatus, setError};
