import {NEWS} from "./actionTypes";
const loadNews = () => ({
    type: NEWS.LOAD
});

const setNews = news => ({
    type: NEWS.LOAD_SUCCESS,
    payload : news
});

const setError = error => ({
    type: NEWS.LOAD_FAIL,
    error
});


export {loadNews, setNews, setError};
