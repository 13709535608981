import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {loadFifthRightSectionNews} from '../../../redux/FifthSection/Right/action';
import {selectFifthRightSectionNewsItems} from '../../../redux/FifthSection/Right/fifthRightSection.selector';
import CardFeature from '../../commonComponent/cardView/CardFeature';
import CardList from '../../commonComponent/cardView/CardList';
import {withRouter} from 'react-router-dom';

class Right extends Component {
    componentDidMount() {
        this.props.loadFifthRightSectionNews({serialNo: 8});
    }

    render() {
        const {fifthSectionRight, history, match} = this.props;
        return (
            <Fragment>
                {fifthSectionRight.length > 0 ? (
                    <div className="col-md-4 telecom_list telecom_list_2">
                        <div className="interview_section popular_news_list2">
                            <div className="content_title">
                                <h3 className="category_title" onClick={() => history.push(`${match.url}category/${fifthSectionRight[0].categories[0].slug}`)}>
                                    {fifthSectionRight[0].categories[0].title}
                                </h3>
                            </div>
                            <CardFeature news={fifthSectionRight[0]}/>
                            <div className="popular_menu_list">
                                <ul>
                                    {fifthSectionRight.filter((news, index) => index > 0).map(news =>
                                        <CardList key={news.id} news={news}/>
                                    )}

                                </ul>
                            </div>
                        </div>
                    </div>
                ) : ''}
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    fifthSectionRight: selectFifthRightSectionNewsItems
});

const mapDispatchToProps = dispatch => ({
    loadFifthRightSectionNews: (serialNo) => dispatch(loadFifthRightSectionNews(serialNo))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Right));