import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import './List.css';

const List = ({news, history, match}) => {
    return(
        <Fragment>
            <div className="new_technology_single" onClick={() =>
                history.push(
                    `${match.url}news/${news.categories[0].slug}/${news.id}/${news.slug}`
                )
            }>
                <div className="new_technology_list">
                    <h3>
                        {news.title}
                    </h3>
                    <div className="new_technology_single_content_bottom">
                        <div className="date">
                            <p>{news.published_time}</p>
                        </div>
                        <div className="comments">
                            <p>{news.comments_count} মন্তব্য</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default withRouter(List);