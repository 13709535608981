import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {loadNewsDetailSlug} from '../../redux/newsDetailsSlug/action';
import NewsDetailContent from '../../components/NewsDetailContent/NewsDetailContent';
import Sidebar from '../../components/sidebar/Sidebar';
//import RelatedNews from '../../components/relatedNews/RelatedNews';
import Comment from '../../components/comment/Comment';
import StoreComment from '../../components/comment/StoreComment';
//import Sharing from '../../components/socialSharing/Sharing';
import {withRouter} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';
import {selectNewsDetailSlugItem} from '../../redux/newsDetailsSlug/newsDetailsSlug.selector';

class NewsDetailSlug extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        const {match} = this.props;
        const {slug} = match.params;
        console.log(slug);
        this.props.loadNewsDetailSlug({slug: slug});
    }

    /*   componentDidUpdate(prevProps) {
     window.scrollTo(0, 0);
     const {dispatch} = this.props;
     if (this.props.match.params.newsId !== prevProps.match.params.newsId) {
     dispatch(loadNewsDetail({newsId: this.props.match.params.newsId}));
     }
     }*/

    render() {
       /* if (typeof window !== 'undefined') {
            var newsPath = window.location.protocol + '//' + window.location.host + window.location.pathname;
        }*/
        const {newsDetail} = this.props;
        //console.log(newsDetail);
        //const comments = newsDetail ? newsDetail.comments : [];
        return (
            <Fragment>
                <section className="bg_white">
                    <div className="banner_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="inner_content">
                                        {newsDetail && newsDetail.image ? (
                                            <Fragment>
                                                <NewsDetailContent newsDetail={newsDetail}/>
                                                {/*<Sharing shareUrl={newsPath}/>*/}
                                                <StoreComment/>
                                                {newsDetail.comments.length > 0 ? newsDetail.comments.map((comment) => <Comment
                                                    key={comment.id}
                                                    comment={comment}/>) : ''}
                                            </Fragment>
                                        ) : ''}
                                    </div>
                                </div>


                                <Sidebar />
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    newsDetail: selectNewsDetailSlugItem
});

const mapDispatchToProps = dispatch => ({
    loadNewsDetailSlug: (slug) => dispatch(loadNewsDetailSlug(slug)),
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(NewsDetailSlug));