import { LATEST_LIST_NEWS } from "./actionTypes";

const latestListError = (state = null, action) => {
  switch (action.type) {
    case LATEST_LIST_NEWS.LOAD_FAIL:
      return action.error;
    case LATEST_LIST_NEWS.LOAD:
    case LATEST_LIST_NEWS.LOAD_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default latestListError;
