import { INTERVIEW_DETAIL } from "./actionTypes";

const loadInterviewDetail = (id) => ({
    type: INTERVIEW_DETAIL.LOAD,
    id
});

const setInterviewDetail = newsDetail => ({
    type: INTERVIEW_DETAIL.LOAD_SUCCESS,
    payload : newsDetail
});

const setError = error => ({
    type: INTERVIEW_DETAIL.LOAD_FAIL,
    error
});


export {loadInterviewDetail, setInterviewDetail, setError};
