import {put, call, takeEvery} from "redux-saga/effects";

import { setTagNews,setError } from "./action";
import { TAGS } from "./actionTypes";
import { fetchNewsDependOnTag } from "../../api";

export function* handleTagNewsLoad(action) {
    try {
        const { slug , pageNumber } = action.tag;
        const news = yield call(fetchNewsDependOnTag, slug, pageNumber);
        yield put(setTagNews(news));
    } catch (error) {
        yield put(setError(error.toString()));
    }
}


export default function* watchTagNewsLoad() {
    yield takeEvery(TAGS.LOAD, handleTagNewsLoad);
}
